// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

const initialState: SearchPhrase = {
	search: '',
};

type Action = { type: typeof actions.SAVE_SEARCH, payload: string };

export default function popUpReducer(state: SearchPhrase = initialState, action: Action) {
	return produce<any>(state, (draft: SearchPhrase) => {
		switch (action.type) {
			case actions.SAVE_SEARCH:
				return action.payload;

			default:
				break;
		}
	});
}
