// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

const initialState: CarClient = {
	isBusy: true,
	item: null,
};

type Action =
	| { type: typeof actions.GET_CAR.START, payload: void }
	| { type: typeof actions.GET_CAR.SUCCESS, payload: CarClient }
	| { type: typeof actions.GET_CAR.FAIL, payload: void };

export default function settingsReducer(state: CarClient = initialState, action: Action) {
	return produce<any>(state, (draft: CarClient) => {
		switch (action.type) {
			case actions.GET_CAR.START:
				draft.isBusy = true;
				break;

			case actions.GET_CAR.SUCCESS:
				draft.item = action.payload.item;
				draft.isBusy = false;
				break;

			case actions.GET_CAR.FAIL:
				draft.isBusy = false;
				break;

			case actions.CLEAR_CAR:
				draft.item = null;
				break;
			default:
				return state;
		}
	});
}
