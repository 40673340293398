// @flow

import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from 'common/components';

import * as css from './Filters.scss';

type Props = {
	className?: string,
	items: Array<any>,
	filterName: string,
	filterNameHe: string,
	setFilters: Function,
	showDropDown: boolean,
	openFilter: Function,
};

type State = {
	isActive: boolean,
	value: string,
};

class Filter extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
	};

	state = {
		isActive: false,
		value: '',
	};

	handleHolderOnClick = () => {
		const { filterName, openFilter } = this.props;
		const { isActive } = this.state;
		if (!isActive) {
			this.setState(
				state => ({
					isActive: !state.isActive,
				}),
				() => {
					openFilter(filterName);
				}
			);
			return;
		}
		this.setState(
			state => ({
				isActive: !state.isActive,
			}),
			() => {
				openFilter('');
			}
		);
	};

	onOptionClick = (e: SyntheticEvent<HTMLDivElement>) => {
		const { id } = e.currentTarget.dataset;
		const { filterName, setFilters, openFilter } = this.props;
		if (filterName === 'price') {
			setFilters({ price: id });
		} else {
			setFilters({ [filterName]: id });
		}

		this.setState(
			{
				value: id,
			},
			() => {
				openFilter('');
			}
		);
	};

	handleResetFilterClick = () => {
		const { filterName, setFilters } = this.props;
		if (filterName === 'price') {
			setFilters({ price: 0 });
		} else {
			setFilters({ [filterName]: '' });
		}

		this.setState({
			value: '',
		});
	};

	render() {
		const { className, items, filterNameHe, showDropDown } = this.props;
		const { value } = this.state;
		const isEmptyValue = _.isEmpty(value);
		const val = isEmptyValue ? filterNameHe : value;

		return (
			<div className={classNames(css.filter, className)}>
				<div className={classNames(css.holder, showDropDown && css.active)} onClick={this.handleHolderOnClick}>
					<input readOnly type="text" value={val} />
					<Icon type="arrowDown" className={css.arrowDownIcon} />
					{!isEmptyValue && <div className={css.resetIcon} onClick={this.handleResetFilterClick} />}
				</div>

				{showDropDown && (
					<div className={css.optionsWrap}>
						{_.map(items, i => {
							return (
								<div key={`${i.id}`} data-id={i.id} className={css.option} onClick={this.onOptionClick}>
									<span>{i.label}</span>
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	}
}

export default Filter;
