/* eslint-disable no-confusing-arrow */
import _ from 'lodash';

export const fileLink = data => {
	if (!data) {
		return null;
	}

	return data.selected === 'file' ? _.get(data, 'file.url', '') : _.get(data, 'remoteURL', '');
};
