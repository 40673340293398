// @flow
import * as React from 'react';
import classNames from 'classnames';

import { CAN_USE_DOM } from 'common/constants';
import Icon from 'common/components/Icon';
import * as css from './ProgressSplash.scss';

type Props = {
	className?: string,
	isBusy: boolean,
	mode?: 'client' | 'admin',
};

export default class ProgressSplash extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
		mode: 'admin',
	};

	render() {
		const { className, isBusy, mode } = this.props;

		if (!CAN_USE_DOM) {
			return null;
		}

		return (
			<div className={classNames(css.progressSplash, isBusy && css.busy, css[mode], className)}>
				<div className={css.bg} />
				<Icon type="preloader" className={css.preloader} />
			</div>
		);
	}
}
