// @flow

import * as React from 'react';
import classNames from 'classnames';

import { Text } from '../../../Text';

import * as css from './Header.scss';

type Props = {
	className?: string,
	title: string,
};

const Header = ({ className, title }: Props) => {
	return (
		<div className={classNames(css.header, className)}>
			<Text className={css.headerTitle}>{title}</Text>
		</div>
	);
};

Header.defaultProps = {
	className: '',
};

export default Header;
