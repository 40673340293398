// @flow
/* eslint-disable class-methods-use-this */
import * as React from 'react';

import { isIOS } from 'common/utils';
import { Responsive } from 'client/components/common/Responsive';
import * as css from './FooterBanner.scss';

type Props = {||};

export default class FooterBanner extends React.PureComponent<Props> {
	componentDidMount() {
		if (Responsive.isMatching(Responsive.MOBILE)) {
			const { googletag } = window;

			if (!window.SHOWN_BANNERS[this.bannerID]) {
				googletag.cmd.push(() => {
					googletag
						.defineSlot(
							isIOS()
								? '/6870/ynet/mobileweb_iphone/banner/ypartner.cars'
								: '/6870/ynet/mobileweb_android/banner/ypartner.cars',
							[
								[320, 50],
								[360, 50],
							],
							this.bannerID
						)
						.addService(googletag.pubads());
					googletag.pubads().enableSingleRequest();
					googletag.enableServices();
				});

				window.SHOWN_BANNERS[this.bannerID] = true;
			}

			googletag.cmd.push(() => googletag.display(this.bannerID));
		}
	}

	get bannerID() {
		if (isIOS()) return 'div-gpt-ad-1615880811292-0';
		return 'div-gpt-ad-1615880757062-0';
	}

	render() {
		return (
			<Responsive query={Responsive.MOBILE}>
				<div className={css.footerBanner}>
					<div id={this.bannerID} />
				</div>
			</Responsive>
		);
	}
}
