// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames';
import { fileLink } from 'common/utils';
import { CAR_ROUTE } from 'client/constants';
import { Responsive } from 'client/components/common';
import Range from 'client/components/common/Range';
import playBtn from 'assets/client/play-btn.svg';
import { dataLayerEvents } from 'common/utils/gtag';

import * as css from './SearchPage.scss';

type Props = {
	rates: Array<RangeType>,
	rateClick: Function,
	videoBtnClick: Function,
	carTitle?: string,
	category?: string,
	video?: string,
	mainImage: any,
	rate?: string,
	id: string,
	isFirst: boolean,
	name: string,
};

class ResultItem extends React.PureComponent<Props> {
	static defaultProps = {
		carTitle: '',
		category: '',
		video: '',
		rate: '',
	};

	onClickLink = (e: SyntheticEvent<HTMLLinkElement>) => {
		const { name } = e.currentTarget.dataset;
		dataLayerEvents.carItemClick(name);
	};

	render() {
		const {
			carTitle,
			id,
			category,
			video,
			mainImage,
			rate,
			isFirst,
			rates,
			rateClick,
			videoBtnClick,
			name,
		} = this.props;
		const neededRateInfo = _.find(rates, i => i.range === rate);
		let rangeType: RangeItemType;
		if (Responsive.isMatching(Responsive.DESKTOP) && isFirst) {
			rangeType = 'medium';
		} else if (Responsive.isMatching(Responsive.DESKTOP) && !isFirst) {
			rangeType = 'small';
		} else if (Responsive.isMatching(Responsive.MOBILE) && isFirst) {
			rangeType = 'mobile-small-type-one';
		} else {
			rangeType = 'mobile-small-type-two';
		}
		const withAnimation = !!isFirst;
		return (
			<div className={classNames(css.item, isFirst && css.firstItem)}>
				{!_.isEmpty(video) && (
					<div className={css.playBtn} onClick={videoBtnClick}>
						<img src={playBtn} alt="" className={css.rateImage} />
					</div>
				)}
				<Link
					data-name={name}
					to={`${CAR_ROUTE.replace(':id', id)}`}
					onClick={this.onClickLink}
					className={css.image}
				>
					<img src={fileLink(mainImage)} alt="" />
				</Link>
				<div className={css.info}>
					<div className={css.rate} data-rate={rate} onClick={rateClick}>
						<Range
							withAnimation={withAnimation}
							range={_.get(neededRateInfo, 'range', '')}
							color={_.get(neededRateInfo, 'color', '')}
							title={_.get(neededRateInfo, 'title', '')}
							type={rangeType}
							onHoverAnimation
						/>
					</div>
					<div className={css.itemInfo}>
						<div className={css.itemCategory}>
							<span>{category}</span>
						</div>
						<div className={css.itemTitle}>
							<span>{carTitle}</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ResultItem;
