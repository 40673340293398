// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';
import { getResults, saveSearch } from 'client/actions';
import { Responsive } from 'client/components/common';

import { Icon } from 'common/components';

import ynetLogoFull from 'assets/client/header/ynet-logo.svg';
import ynetLogoSmall from 'assets/client/header/ynet-logo_small.svg';
import carsLogo from 'assets/client/header/ynet-cars-logo.png';
import Search from '../Search';
import Filters from '../Filters';

import * as css from './Header.scss';

type Props = {
	isFull?: boolean,
	className?: string,
	getResults: Function,
	saveSearch: Function,
	withFilters?: boolean,
	manufactures: Array<any>,
};

type State = {
	activeSearch: boolean,
	activeFilters: boolean,
};

class Header extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
		isFull: false,
		withFilters: false,
	};

	state = {
		activeSearch: false,
		activeFilters: false,
	};

	static getDerivedStateFromProps(props: Props, state: State) {
		if (!props.withFilters && state.activeFilters) {
			return {
				activeFilters: false,
				activeSearch: false,
			};
		}

		return null;
	}

	handleSearchBtnClick = () => {
		const { activeSearch, activeFilters } = this.state;
		const { withFilters } = this.props;

		if (withFilters) {
			this.setState({
				activeSearch: !activeSearch,
				activeFilters: !activeFilters,
			});
		} else {
			this.setState({
				activeSearch: !activeSearch,
			});
		}
	};

	closeFilters = () => {
		this.setState({
			activeSearch: false,
			activeFilters: false,
		});
	};

	renderSerchBtn = () => {
		return (
			<div
				className={classNames(css.searchBtn, this.state.activeSearch && css.active)}
				onClick={this.handleSearchBtnClick}
			>
				<Icon type="search" className={css.searchIcon} />
			</div>
		);
	};

	onSearch = (search: string) => {
		this.props.saveSearch(search);
		this.props.getResults({ search });
		this.setState({
			activeSearch: false,
		});
	};

	renderSearch = () => {
		const { manufactures } = this.props;
		const autocompleteArray = _.reduce(
			manufactures,
			(acc, item, index) => {
				acc.push(item.label);
				return acc;
			},
			[]
		);

		return <Search onSearch={this.onSearch} autocompleteArray={autocompleteArray} />;
	};

	renderFilters = () => {
		return <Filters closeFilters={this.closeFilters} />;
	};

	render() {
		const { isFull, className, withFilters } = this.props;
		const { activeSearch, activeFilters } = this.state;
		const ynetLink = 'https://www.ynet.co.il';
		const ynetLogo = isFull ? ynetLogoFull : ynetLogoSmall;

		if (Responsive.isMatching(Responsive.DESKTOP)) {
			return (
				<div className={classNames(css.header, isFull && css.full, className)}>
					<div className={css.top}>
						<a href={ynetLink} rel="noopener noreferrer" target="_blank">
							<img src={ynetLogo} className={css.ynetLogo} alt="ynet-logo" />
						</a>

						{isFull && this.renderSearch()}

						<Link to="/">
							<img src={carsLogo} className={css.carsLogo} alt="cars-logo" />
						</Link>

						{!isFull && this.renderSerchBtn()}
						{!isFull && activeSearch && this.renderSearch()}
					</div>
				</div>
			);
		}
		return (
			<div className={classNames(css.header, className, activeFilters && css.fixedHeader)}>
				<div className={css.top}>
					<div>
						<a href={ynetLink} rel="noopener noreferrer" target="_blank">
							<img src={ynetLogoSmall} className={css.ynetLogo} alt="ynet-logo" />
						</a>
						<Link to="/">
							<img src={carsLogo} className={css.carsLogo} alt="cars-logo" />
						</Link>
					</div>
					<div>{this.renderSerchBtn()}</div>

					{activeSearch && this.renderSearch()}
				</div>
				{activeFilters && withFilters && this.renderFilters()}
			</div>
		);
	}
}

const mapState = (state: ClientStore) => ({
	manufactures: state.settings.data.filters.manufacturer,
});

const mapDispatch = {
	getResults,
	saveSearch,
};

export default connect(mapState, mapDispatch)(Header);
