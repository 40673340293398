// @flow
/* eslint-disable max-len */
import * as React from 'react';

// import _ from 'lodash';

import * as css from './VideoPopUp.scss';

type Props = {
	onClose: Function,
	videoSrc: string,
};

type State = {
	src: string,
};

export default class VideoPopUp extends React.PureComponent<Props, State> {
	state = {
		src: '',
	};

	componentDidMount() {
		const { videoSrc } = this.props;
		const videoId = videoSrc.split('=')[1];
		const needSrc = `https://www.youtube.com/embed/${videoId}`;
		setTimeout(() => {
			this.setState({
				src: needSrc,
			});
		}, 200);
	}

	closePopup = (e: SyntheticEvent<HTMLElement>) => {
		e.preventDefault();
		const { onClose } = this.props;
		onClose();
	};
	render() {
		const { src } = this.state;
		return (
			<div className={css.videoPopUp}>
				<div className={css.content}>
					<a href="#" onClick={this.closePopup} className={css.closeBtn}></a>
					<div className={css.videoWrapper}>
						<iframe
							title="uniqe"
							src={src}
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
							className={css.youtubePlayer}
						></iframe>
					</div>
				</div>
			</div>
		);
	}
}
