// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';
import { getResults, saveFilters } from 'client/actions';
import iconBack from 'assets/client/icon-back-red.png';
import Filter from './Filter';

import * as css from './Filters.scss';

type Props = {
	className?: string,
	filters: FiltersType,
	results: Array<CarsItem>,
	search: string,
	getResults: Function,
	closeFilters?: Function,
	saveFilters: Function,
};

type State = {
	filtersArray: any,
	activeFilter: string,
};

const FILTERS_MAP = {
	// MANUFACTURER: {
	// 	en: 'manufacturer',
	// 	he: 'יצרן',
	// },
	YEAR: {
		en: 'year',
		he: 'שנה',
	},
	PRICE: {
		en: 'price',
		he: 'מחיר',
	},
	RANGE: {
		en: 'range',
		he: 'ציון',
	},
	CATEGORY: {
		en: 'category',
		he: 'קטגוריה',
	},
	NAME: {
		en: 'names',
		he: 'דגם',
	},
};

class Filters extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
		closeFilters: () => {},
	};

	state = {
		filtersArray: {},
		activeFilter: '',
	};

	getFilters = filters => {
		const { filtersArray } = this.state;
		const { search } = this.props;
		let currentFilters;

		if (
			_.values(filters)[0] === 0 ||
			(typeof _.values(filters)[0] !== 'number' && _.isEmpty(_.values(filters)[0]))
		) {
			currentFilters = _.omit(filtersArray, [_.keys(filters)[0]]);
			this.setState({ filtersArray: { ...currentFilters } });
			this.props.saveFilters({ ...currentFilters });
			this.props.getResults({ search, filter: { ...currentFilters } });
		} else {
			currentFilters = { ...filtersArray, ...filters };
			this.setState({ filtersArray: { ...currentFilters } });
			this.props.saveFilters({ ...currentFilters });
			this.props.getResults({ search, filter: { ...currentFilters } });
		}
	};

	openFilter = name => {
		this.setState({
			activeFilter: name,
		});
	};

	render() {
		const { className, filters, results, closeFilters } = this.props;
		const title = 'סנן לפי:';
		const manufacturer = results[0].manufacturer.keyLabel;
		const { activeFilter } = this.state;

		return (
			<div className={classNames(css.filters, className)}>
				<div className={css.title}>
					<span>{title}</span>
					<img src={iconBack} alt="" className={css.iconBack} onClick={closeFilters} />
				</div>
				{_.map(FILTERS_MAP, filterName => {
					if (filterName.en === FILTERS_MAP.RANGE.en) {
						return (
							<Filter
								key={FILTERS_MAP.RANGE.en}
								filterName={FILTERS_MAP.RANGE.en}
								filterNameHe={FILTERS_MAP.RANGE.he}
								setFilters={this.getFilters}
								items={_.map(filters[FILTERS_MAP.RANGE.en], (i: RangeType) => {
									return { id: i.range, label: `${i.range} - ${i.title}` };
								})}
								showDropDown={filterName.en === activeFilter}
								openFilter={this.openFilter}
							/>
						);
					}
					if (filterName.en === FILTERS_MAP.NAME.en) {
						const currentManufacturer = filters[filterName.en][manufacturer];

						return (
							<Filter
								key={filterName.en}
								filterName={filterName.en}
								filterNameHe={filterName.he}
								setFilters={this.getFilters}
								items={_.map(currentManufacturer, i => {
									return { id: i, label: i };
								})}
								showDropDown={filterName.en === activeFilter}
								openFilter={this.openFilter}
							/>
						);
					}
					return (
						<Filter
							key={filterName.en}
							filterName={filterName.en}
							filterNameHe={filterName.he}
							setFilters={this.getFilters}
							items={_.map(filters[filterName.en], i => {
								return { id: i, label: i };
							})}
							showDropDown={filterName.en === activeFilter}
							openFilter={this.openFilter}
						/>
					);
				})}
			</div>
		);
	}
}

const mapState = (state: ClientStore) => ({
	filters: _.omit(state.settings.data.filters, ['manufacturer']),
	search: state.search.search,
	results: state.results.items,
});
const mapDispatch = {
	getResults,
	saveFilters,
};

export default connect(mapState, mapDispatch)(Filters);
