// @flow
import { connect } from 'react-redux';
import { getClientItems, setPopUp, clearCar } from 'client/actions';
import Intro from './Intro';

const mapState = (state: ClientStore) => ({
	settings: state.settings,
	cars: state.cars.items,
});
const mapDispatch = {
	getClientItems,
	setPopUp,
	clearCar,
};

export default connect(mapState, mapDispatch)(Intro);
