// @flow
import * as React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import Header from './Header';
import Footer from './Footer';

import * as css from './PopUp.scss';

type Props = {
	className?: string,
	visible?: boolean,
	content?: React.Node,
};

type State = {
	isRemoved: boolean,
};

export default class PopUp extends React.Component<Props, State> {
	static defaultProps = {
		className: '',
		visible: false,
		content: <div />,
	};

	constructor(props: Props) {
		super(props);

		if (typeof window !== 'undefined') {
			const container = document.createElement('div');
			container.classList.add('popup-manager');
			if (document && document.body) {
				document.body.appendChild(container);
			}
		}

		this.state = {
			isRemoved: false,
		};
	}

	componentDidUpdate(prevProps: Props) {
		const { visible } = this.props;

		if (prevProps.visible && !visible) {
			setTimeout(() => this.setIsRemoved(true), parseInt(css.appearDuration, 10));
		}

		if (!prevProps.visible && visible) {
			this.setIsRemoved(false);
		}
	}

	setIsRemoved = (state: boolean) => {
		this.setState({ isRemoved: state });
	};

	render() {
		const { className, visible, content } = this.props;
		const { isRemoved } = this.state;

		if (typeof window !== 'undefined') {
			const container = document.querySelector('.popup-manager');

			if (container) {
				return ReactDOM.createPortal(
					<div className={classNames(css.popUp, className, { [css.visible]: visible })}>
						{!isRemoved && content}
					</div>,
					container
				);
			}
		}

		return null;
	}
}

export { Header, Footer };
