// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import { fileLink } from 'common/utils';
import _ from 'lodash';

import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import carsLogo from 'assets/client/header/ynet-cars-logo.png';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import FooterBanner from 'client/components/common/FooterBanner';
import faviconImg from 'assets/favicon1.png';
import ynetLogoSmall from 'assets/client/header/ynet-logo_small.svg';
import * as css from './CarIframePage.scss';
import RateTags from './RateTags';
import { CAR_ROUTE } from '../../../constants/routes';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
	settings: Settings,
	car?: CarsItem,
	getCar: Function,
	match?: RouterMatch,
|};

class CarIframePage extends React.PureComponent<Props> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
		match: {},
		car: null,
	};

	componentDidMount() {
		const { onTransitionComplete, getCar, match, car } = this.props;
		const id = _.get(match, 'params.id', '');
		this.transitionCompleteTimeout = setTimeout(onTransitionComplete, parseInt(css.transitionIn, 10));
		if (!car) {
			getCar({ collection: 'cars', id });
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	renderHelmet = () => {
		const { car } = this.props;
		// eslint-disable-next-line max-len
		const title = _.get(car, 'seoTitle', '');
		// eslint-disable-next-line max-len
		const description = _.get(car, 'seoDescription', '');
		const ogTitle = _.get(car, 'shareTitle', '');
		const ogDescription = _.get(car, 'shareDescription', '');
		const image = fileLink(_.get(car, 'shareImage', ''));
		return (
			<Helmet
				title={title}
				link={[{ rel: 'icon', type: 'image/png', href: faviconImg }]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: ogTitle },
					{ property: 'og:description', content: ogDescription },
					{ property: 'og:image', content: image },
				]}
			/>
		);
	};

	render() {
		const { className, transitionState, settings, car, match } = this.props;
		const ynetLink = 'https://www.ynet.co.il';

		const goodTags = _.get(car, 'goodTags', '');
		const badTags = _.get(car, 'badTags', '');
		const normalTags = _.get(car, 'normalTags', '');
		const neededRateInfo: ?RangeType = _.find<RangeType>(settings.data.filters.range, {
			range: car ? car.rate : '',
		});

		return (
			<div className={classNames(css.carIframePage, css[transitionState], className)}>
				{this.renderHelmet()}
				<div className={css.header}>
					<Link to="/">
						<img src={carsLogo} className={css.carsLogo} alt="cars-logo" />
					</Link>
					<a href={ynetLink} rel="noopener noreferrer" target="_blank">
						<img src={ynetLogoSmall} className={css.ynetLogo} alt="ynet-logo" />
					</a>
				</div>
				<div className={css.generalInfo}>
					<h1 className={css.title}>{car && car.title}</h1>
					{neededRateInfo && (
						<RateTags
							goodTags={goodTags}
							badTags={badTags}
							normalTags={normalTags}
							rate={neededRateInfo.range}
						/>
					)}
				</div>
				<div className={css.bottom}>
					<Link to={`${CAR_ROUTE.replace(':id', _.get(match, 'params.id', ''))}`} target="_blank">
						<button className={css.submitBtn} type="button">
							קרא עוד
						</button>
					</Link>
				</div>
				<FooterBanner />
			</div>
		);
	}
}
export default withTransition(CarIframePage);
