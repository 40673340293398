/* eslint-disable react/no-unescaped-entities */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import _ from 'lodash';

import { Responsive } from 'client/components/common';
import GeneralInfo from './GeneralInfo';
import RateTags from './RateTags';
// import TechInfo from './TechInfo';
// import ContactForm from './ContactForm';

import * as css from './Asside.scss';

type Props = {|
	info: CarsItem,
	settings: Settings,
	rateClick: Function,
|};

class Asside extends React.PureComponent<Props> {
	componentDidMount() {}

	componentDidUpdate(prevProps: Props) {}

	render() {
		const { info, settings, rateClick } = this.props;
		const {
			category,
			title,
			description,
			// goodTags,
			// badTags,
			// normalTags,
			// length,
			// height,
			// width,
			// wheelsBase,
			// trunkSize,
			// engineType,
			// engineSize,
			// gearType,
			// numberOfDoors,
			// numberOfStars,
		} = info;

		const goodTags = _.get(info, 'goodTags', '');
		const badTags = _.get(info, 'badTags', '');
		const normalTags = _.get(info, 'normalTags', '');
		const neededRateInfo: ?RangeType = _.find<RangeType>(settings.data.filters.range, { range: info.rate });
		const price = _.get(info, 'price', 0);

		return (
			<div className={css.asside}>
				<GeneralInfo
					category={category}
					title={title}
					description={description}
					rate={_.get(neededRateInfo, 'range', '')}
					color={_.get(neededRateInfo, 'color', '')}
					rangeTitle={_.get(neededRateInfo, 'title', '')}
					rateClick={rateClick}
					goodTags={goodTags}
					badTags={badTags}
					normalTags={normalTags}
					price={price}
				/>
				{Responsive.isMatching(Responsive.MOBILE) && (
					<RateTags
						goodTags={goodTags}
						badTags={badTags}
						normalTags={normalTags}
						rate={_.get(neededRateInfo, 'range', '')}
						color={_.get(neededRateInfo, 'color', '')}
						title={_.get(neededRateInfo, 'title', '')}
						rateClick={rateClick}
						price={price}
					/>
				)}
				<div className={css.additionalLinks}>
					<p>
						בין מומחי הרכב שהערכתם לכל דגם ב"מנוע חיפוש" נלקחה בחשבון, כתבי הרכב של המגזינים 
						<a href="https://www.autocar.co.uk/" rel="noopener noreferrer" target="_blank">
							אוטוקאר
						</a>
						,{' '}
						<a href="https://www.autoexpress.co.uk/" rel="noopener noreferrer" target="_blank">
							אוטואקספרס
						</a>{' '}
						ו
						<a href="https://www.topgear.com/" rel="noopener noreferrer" target="_blank">
							טופגיר
						</a>{' '}
						הבריטיים,{' '}
						<a href="https://www.topgear.com/" rel="noopener noreferrer" target="_blank">
							אוטו, מוטור וספורט{' '}
						</a>{' '}
						ו
						<a href="https://www.autobild.de/" rel="noopener noreferrer" target="_blank">
							אוטובילד
						</a>{' '}
						הגרמניים,{' '}
						<a href="https://www.caradvice.com.au/" rel="noopener noreferrer" target="_blank">
							קאראדוויז
						</a>{' '}
						האוסטרלי, 
						<a href="https://www.roadandtrack.com/" rel="noopener noreferrer" target="_blank">
							רואד אנד טראק
						</a>
						,{' '}
						<a href="https://www.caranddriver.com/" rel="noopener noreferrer" target="_blank">
							קאר אנד דרייבר
						</a>{' '}
						ו
						<a href="https://www.motortrend.com/" rel="noopener noreferrer" target="_blank">
							מוטורטרנד
						</a>{' '}
						האמריקאיים ואחרים.
					</p>
				</div>
				{/* <TechInfo
					length={length}
					height={height}
					width={width}
					wheelsBase={wheelsBase}
					trunkSize={trunkSize}
					category={category}
					engineType={engineType}
					engineSize={engineSize}
					gearType={gearType}
					numberOfDoors={numberOfDoors}
					numberOfStars={numberOfStars}
				/> */}
				{/* <ContactForm info={this.props.info} /> */}
			</div>
		);
	}
}

export default Asside;
