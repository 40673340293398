// @flow
import * as React from 'react';

import _ from 'lodash';
import Item from './Item';
import * as css from './TwoItemsVerOne.scss';

type Props = {
	items: Array<CarsItem>,
	rates: Array<RangeType>,
	rateClick: Function,
	mobileRangeType: RangeItemType,
};

class TwoItemsVerOne extends React.PureComponent<Props> {
	render() {
		const { items, rates, rateClick, mobileRangeType } = this.props;
		return (
			<div className={css.items}>
				{_.map(items, i => {
					const neededRateInfo: any = _.find<RangeType>(rates, { range: i.rate });
					// eslint-disable-next-line no-lone-blocks
					{
						return (
							<Item
								key={i.createdDate}
								rate={neededRateInfo}
								item={i}
								rateClick={rateClick}
								mobileRangeType={mobileRangeType}
							/>
						);
					}
				})}
			</div>
		);
	}
}

export default TwoItemsVerOne;
