// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

const initialState: Settings = {};

type Action =
	| { type: typeof actions.GET_SETTINGS.START, payload: void }
	| { type: typeof actions.GET_SETTINGS.SUCCESS, payload: GetSettingsResult }
	| { type: typeof actions.GET_SETTINGS.FAIL, payload: void };

export default function settingsReducer(state: Settings = initialState, action: Action) {
	return produce<any>(state, (draft: Settings) => {
		switch (action.type) {
			case actions.GET_SETTINGS.START:
				draft.isBusy = true;
				break;

			case actions.GET_SETTINGS.SUCCESS:
				draft.data = { ...action.payload };
				draft.isBusy = false;
				break;

			case actions.GET_SETTINGS.FAIL:
				draft.isBusy = false;
				break;

			default:
				return state;
		}
	});
}
