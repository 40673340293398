// @flow
import Api from './ApiWorker';

export const createItem = <I: AbstractItem>(params: CreateItemParams<CarsItem>): Promise<BackEndResponse<I>> =>
	Api.send<I>({
		url: '/crud/create',
		method: 'POST',
		body: params,
		secure: true,
	});

export const getItem = <I>(params: GetItemParams): Promise<BackEndResponse<I>> =>
	Api.send<I>({
		url: '/crud/get',
		method: 'POST',
		body: params,
		secure: true,
	});

export const getItems = <I, F>(params: GetItemsParams<F>): Promise<BackEndResponse<GetItemsResult<I>>> =>
	Api.send<GetItemsResult<I>>({
		url: '/crud/get-items',
		method: 'POST',
		body: params,
		secure: true,
	});

export const updateItem = <I: AbstractItem>(params: UpdateItemParams<I>): Promise<BackEndResponse<I>> =>
	Api.send<I>({
		url: '/crud/update',
		method: 'POST',
		body: params,
		secure: true,
	});

export const deleteItem = (params: DeleteItemParams): Promise<BackEndResponse<DeleteItemParams>> =>
	Api.send<DeleteItemParams>({
		url: '/crud/delete',
		method: 'POST',
		body: params,
		secure: true,
	});

/* eslint-disable indent */
export const updateItemsOrder = <I: UpdateItemOrderParams>(
	params: UpdateItemsOrderParams<I>
): Promise<BackEndResponse<UpdateItemsOrderResult<I>>> =>
	Api.send<UpdateItemsOrderResult<I>>({
		url: '/crud/update-order',
		method: 'POST',
		body: params,
		secure: true,
	});
/* eslint-enable indent */

/**
	Client's routes:
 */
export const getClientItems = <I, F>(params: GetItemsParams<F>): Promise<BackEndResponse<GetItemsResult<I>>> =>
	Api.send<GetItemsResult<I>>({
		url: '/crud/get-client-items',
		method: 'GET',
		body: params,
		secure: false,
	});

export const getResults = <I, F>(params: GetItemsParams<F>): Promise<BackEndResponse<GetFilteredItemsResult<I>>> =>
	Api.send<GetFilteredItemsResult<I>>({
		url: '/crud/get-results',
		method: 'POST',
		body: params,
		secure: false,
	});

export const getClientItem = <I>(params: GetItemParams): Promise<BackEndResponse<I>> =>
	Api.send<I>({
		url: `/crud/get-client-item/${params.id}`,
		method: 'GET',
		// body: params,
		secure: false,
	});
