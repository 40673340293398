// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';

import * as css from './Breadcrumbs.scss';

type BreadcrumbsItem = {
	name: string,
	to: string,
};

type Props = {
	className?: string,
	path: Array<BreadcrumbsItem>,
};

class Breadcrumbs extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
	};

	render() {
		const { className, path } = this.props;
		return (
			<div className={classNames(css.breadcrumbs, className)}>
				{_.map(path, (pathItem: BreadcrumbsItem, index: number) => {
					if (index === 0) {
						return (
							<Link to={pathItem.to} className={css.link} key={index}>
								<p className={css.text}>{pathItem.name}</p>
							</Link>
						);
					}
					return (
						<p className={css.text} key={index}>
							{pathItem.name}
						</p>
					);
				})}
			</div>
		);
	}
}

export { Breadcrumbs };
