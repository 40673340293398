// @flow
import { connect } from 'react-redux';
import { setPopUp } from 'client/actions';
import SearchPage from './SearchPage';

const mapState = (state: ClientStore) => ({
	settings: state.settings,
	results: state.results.items,
	isBusy: state.results.isBusy,
	similar: state.results.similar,
	car: state.car.item,
	searchPhrase: state.search.search,
	userFilters: state.userFilters,
});
const mapDispatch = {
	setPopUp,
};

export default connect(mapState, mapDispatch)(SearchPage);
