// @flow
import * as React from 'react';
import { fileLink } from 'common/utils';
import { Responsive } from 'client/components/common';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { CAR_ROUTE } from 'client/constants';
import Range from 'client/components/common/Range';
// import playBtn from 'assets/client/play-btn.svg';
import { dataLayerEvents } from 'common/utils/gtag';
import * as css from './SingleItem.scss';

type Props = {
	item: CarsItem,
	rates: Array<RangeType>,
	rateClick: Function,
	// videoBtnClick: Function,
	mobileRangeType: RangeItemType,
};

class SingleItem extends React.PureComponent<Props> {
	onClickLink = (e: SyntheticEvent<HTMLLinkElement>) => {
		const { name } = e.currentTarget.dataset;
		dataLayerEvents.carItemClick(name);
	};

	render() {
		// const { item, rates, rateClick, videoBtnClick, mobileRangeType } = this.props;
		const { item, rates, rateClick, mobileRangeType } = this.props;
		const neededRateInfo = _.find(rates, i => i.range === item.rate);
		const rangeType = Responsive.isMatching(Responsive.DESKTOP) ? 'small' : mobileRangeType;

		return (
			<div className={css.item}>
				{/* <div className={css.playBtn} onClick={videoBtnClick}>
					<img src={playBtn} alt="" className={css.rateImage} />
				</div> */}
				<Link
					data-name={item.name}
					to={`${CAR_ROUTE.replace(':id', item.id)}`}
					onClick={this.onClickLink}
					className={css.mediaWrapper}
				>
					<div className={css.media} style={{ backgroundImage: `url(${fileLink(item.mainImage)})` }}></div>
				</Link>
				<div className={css.info}>
					<div className={css.rateWrapper} data-rate={item.rate} onClick={rateClick}>
						<Range
							withAnimation={false}
							color={_.get(neededRateInfo, 'color', '')}
							range={_.get(neededRateInfo, 'range', '')}
							type={rangeType}
							title={_.get(neededRateInfo, 'title', '')}
							onHoverAnimation
						/>
					</div>

					<div className={css.itemCategory}>
						<span>{item.category}</span>
					</div>

					<Link data-name={item.name} to={`${CAR_ROUTE.replace(':id', item.id)}`} onClick={this.onClickLink}>
						<div className={css.itemTitle}>
							<span>{item.title}</span>
						</div>
					</Link>
				</div>
			</div>
		);
	}
}

export default SingleItem;
