import React, { createRef } from 'react';
import classNames from 'classnames';
import PT from 'prop-types';
import { noop } from 'lodash';

import * as css from './ScrollBar.scss';

class ScrollBar extends React.Component {
	constructor(props) {
		super(props);

		this.scrollHandleRef = createRef();
		this.scrollTrackRef = createRef();

		this.scrollingDistance = 0;
		this.targetDistance = 0;
		this.isWheelListenerRegistered = false;
		this.scrollingFlagTimeoutID = 0;

		this.startDragPosY = 0;
		this.startHandlerPosY = 0;
		this.currentHandlerPosY = 0;
		this.scrollingRatioMin = 0.2;

		this.state = {
			scrollingMaxDistance: 1,
			scrollingRatio: 1,
			isScrolling: true,
		};
	}

	componentDidMount() {
		this.updateScrollingDistance(0);
		this.onWindowResize();
		window.addEventListener('resize', this.onWindowResize);

		if (this.props.contentWrapRef && this.props.contentWrapRef.current) {
			this.props.contentWrapRef.current.addEventListener('wheel', this.onMouseWheel);
		}

		setTimeout(() => this.setState({ isScrolling: false }), 1000);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.children !== prevProps.children) {
			this.onWindowResize();
		}

		if (this.props.contentWrapRef !== prevProps.contentWrapRef) {
			if (this.props.contentWrapRef.current && !this.isWheelListenerRegistered) {
				this.updateScrollingDistance(0);
				this.onWindowResize();
				this.props.contentWrapRef.current.addEventListener('wheel', this.onMouseWheel);
				this.isWheelListenerRegistered = true;
			}
		}
	}

	componentWillUnmount() {
		const { contentWrapRef } = this.props;

		window.removeEventListener('resize', this.onWindowResize);
		if (contentWrapRef.current) {
			contentWrapRef.current.removeEventListener('wheel', this.onMouseWheel);
		}
	}

	update = () => {
		this.onWindowResize();
	};

	updateScrollingDistance = distance => {
		const { contentRef, onProgressUpdate, onScroll } = this.props;

		if (!contentRef) {
			return;
		}

		const scrollTrackH = this.scrollTrackRef.current.offsetHeight;
		const scrollHandlerH = this.scrollHandleRef.current.offsetHeight;
		const scrollHandlerMaxDistance = scrollTrackH - scrollHandlerH;

		const { scrollingMaxDistance } = this.state;
		const updatedScrollingProgress = distance / scrollingMaxDistance;
		const handleTargetY = updatedScrollingProgress * scrollHandlerMaxDistance;
		const content = contentRef.current;
		const scrollHandle = this.scrollHandleRef.current;

		if (content) {
			content.style.transform = `translate3d(0, -${updatedScrollingProgress * scrollingMaxDistance}px, 0)`;
		}

		if (scrollHandle) {
			scrollHandle.style.transform = `translate3d(0, ${handleTargetY}px, 0)`;
			this.currentHandlerPosY = handleTargetY;
		}

		const isUpdated = this.scrollingDistance !== distance;
		this.scrollingDistance = distance;

		if (isUpdated) {
			onProgressUpdate(updatedScrollingProgress);
			onScroll(distance);

			if (!this.state.isScrolling) {
				this.setState({ isScrolling: true });
			}

			clearTimeout(this.scrollingFlagTimeoutID);
			this.scrollingFlagTimeoutID = setTimeout(this.resetScrollingFlag, 3000);
		}
	};

	resetScrollingFlag = () => {
		this.setState({ isScrolling: false });
	};

	onWindowResize = () => {
		const { contentWrapRef, contentRef } = this.props;

		if (contentWrapRef && contentRef && contentWrapRef.current && contentRef.current) {
			this.setState({
				scrollingMaxDistance: Math.max(
					contentRef.current.offsetHeight - contentWrapRef.current.offsetHeight,
					0
				),
				scrollingRatio: Math.min(contentWrapRef.current.offsetHeight / contentRef.current.offsetHeight, 1),
			});
		}
	};

	onContainerMouseListener = e => {
		switch (e.type) {
			case 'mouseenter': {
				if (!this.state.isScrolling) {
					this.setState({ isScrolling: true });
				}

				clearTimeout(this.scrollingFlagTimeoutID);
				break;
			}

			case 'mouseleave': {
				clearTimeout(this.scrollingFlagTimeoutID);
				this.scrollingFlagTimeoutID = setTimeout(this.resetScrollingFlag, 3000);
				break;
			}

			default: {
				break;
			}
		}
	};

	onScrollHandleMouseDown = e => {
		this.startDragPosY = e.screenY;
		this.startHandlerPosY = this.currentHandlerPosY;

		document.addEventListener('mousemove', this.onDocumentMouseListener);
		document.addEventListener('mouseup', this.onDocumentMouseListener);
	};

	onDocumentMouseListener = e => {
		const currentPosY = e.screenY;
		const scrollHandle = this.scrollHandleRef.current;

		switch (e.type) {
			case 'mousemove': {
				if (scrollHandle) {
					const { scrollingMaxDistance } = this.state;
					const diff = currentPosY - this.startDragPosY;
					const handleTargetY = this.startHandlerPosY + diff;

					const scrollTrackH = this.scrollTrackRef.current.offsetHeight;
					const scrollHandlerH = this.scrollHandleRef.current.offsetHeight;
					const scrollHandlerMaxDistance = scrollTrackH - scrollHandlerH;
					const updatedScrollingProgress = handleTargetY / scrollHandlerMaxDistance;
					let distance = updatedScrollingProgress * scrollingMaxDistance;

					if (distance < 0) {
						distance = 0;
					}

					if (distance > scrollingMaxDistance) {
						distance = scrollingMaxDistance;
					}

					this.updateScrollingDistance(distance);
				}
				break;
			}

			case 'mouseup': {
				document.removeEventListener('mousemove', this.onDocumentMouseListener);
				document.removeEventListener('mouseup', this.onDocumentMouseListener);
				break;
			}

			default: {
				break;
			}
		}
	};

	onMouseWheel = e => {
		e.preventDefault();

		const { scrollingMaxDistance } = this.state;
		const { deltaY } = e;

		this.targetDistance =
			this.scrollingDistance +
			(this.targetDistance < 0 || this.targetDistance > scrollingMaxDistance ? deltaY / 2 : deltaY);

		if (this.targetDistance < 0) {
			this.targetDistance = 0;
		}

		if (this.targetDistance > scrollingMaxDistance) {
			this.targetDistance = scrollingMaxDistance;
		}

		this.updateScrollingDistance(this.targetDistance);
	};

	render() {
		const { className, showScrollBarAlways } = this.props;
		const { scrollingRatio, isScrolling } = this.state;

		return (
			<div
				className={classNames(css.scrollBar, className, {
					[css.hide]: showScrollBarAlways ? false : scrollingRatio === 1 || !isScrolling,
				})}
				onMouseEnter={this.onContainerMouseListener}
				onMouseLeave={this.onContainerMouseListener}
			>
				<div className={css.scrollTrack} ref={this.scrollTrackRef} />
				<div
					className={css.scrollHandle}
					onMouseDown={this.onScrollHandleMouseDown}
					style={{ height: `${Math.max(scrollingRatio, this.scrollingRatioMin) * 100}%` }}
					ref={this.scrollHandleRef}
				/>
			</div>
		);
	}
}

ScrollBar.defaultProps = {
	className: '',
	children: null,
	contentWrapRef: null,
	contentRef: null,
	onProgressUpdate: noop,
	onScroll: noop,
	showScrollBarAlways: false,
};

ScrollBar.propTypes = {
	className: PT.string,
	children: PT.oneOfType([PT.node, PT.arrayOf(PT.node)]),
	// eslint-disable-next-line
	contentWrapRef: PT.shape({ current: PT.any }),
	// eslint-disable-next-line
	contentRef: PT.shape({ current: PT.any }),
	onProgressUpdate: PT.func,
	onScroll: PT.func,
	showScrollBarAlways: PT.bool,
};

export default ScrollBar;
