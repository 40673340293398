// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

const initialState: CarsClient = {
	isBusy: true,
	items: [],
};

type Action =
	| { type: typeof actions.GET_CARS.START, payload: void }
	| { type: typeof actions.GET_CARS.SUCCESS, payload: CarsClient }
	| { type: typeof actions.GET_CARS.FAIL, payload: void };

export default function settingsReducer(state: CarsClient = initialState, action: Action) {
	return produce<any>(state, (draft: CarsClient) => {
		switch (action.type) {
			case actions.GET_CARS.START:
				draft.isBusy = true;
				break;

			case actions.GET_CARS.SUCCESS:
				draft.items = action.payload.items;
				draft.isBusy = false;
				break;

			case actions.GET_CARS.FAIL:
				draft.isBusy = false;
				break;

			default:
				return state;
		}
	});
}
