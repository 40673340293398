/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as css from './Icon.scss';

const Icon = ({ type, className, wrapInSquare }) => {
	let Content;

	switch (type) {
		case 'preloader':
			Content = (
				<svg viewBox="25 25 50 50">
					<circle cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
				</svg>
			);
			break;
		case 'search':
			Content = (
				<svg viewBox="0 0 28.359 28.035">
					<path
						id="Icon_map-search"
						fill="#dfdfdf"
						d="M23.946 19.854a12.164 12.164 0 1 0-4.095 4.091l5.855 5.53 4.094-4.094zm-10.37 1.231a7.5 7.5 0 1 1 7.507-7.5 7.516 7.516 0 0 1-7.507 7.5z"
						data-name="Icon map-search"
						transform="translate(-1.44 -1.44)"
					/>
				</svg>
			);
			break;
		case 'arrowLeft':
			Content = (
				<svg xmlns="http://www.w3.org/2000/svg" width="24.248" height="23.634" viewBox="0 0 24.248 23.634">
					<path
						id="Icon_awesome-arrow-left"
						fill="#dfdfdf"
						d="M13.935 24.7l-1.2 1.2a1.294 1.294 0 0 1-1.835 0L.378 15.384a1.294 1.294 0 0 1 0-1.835L10.9 3.029a1.294 1.294 0 0 1 1.835 0l1.2 1.2a1.3 1.3 0 0 1-.022 1.856L7.392 12.3h15.554a1.3 1.3 0 0 1 1.3 1.3v1.73a1.3 1.3 0 0 1-1.3 1.3H7.392l6.521 6.213a1.291 1.291 0 0 1 .022 1.857z"
						data-name="Icon awesome-arrow-left"
						transform="translate(.004 -2.647)"
					/>
				</svg>
			);
			break;
		case 'arrowDown':
			Content = (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.475 20.475">
					<path
						id="Path_13716"
						fill="#f05"
						d="M1.713 0l12.743 12.848L0 14.5z"
						data-name="Path 13716"
						transform="rotate(-45 12.339 5.111)"
					/>
				</svg>
			);
			break;

		case 'arrowsMore':
			Content = (
				<svg xmlns="http://www.w3.org/2000/svg" width="34.221" height="33.076" viewBox="0 0 34.221 33.076">
					<g
						id="Icon_feather-chevrons-left"
						data-name="Icon feather-chevrons-left"
						transform="translate(-7.5 -8.379)"
					>
						<path
							id="Path_226"
							d="M23.417 39.333L9 24.917 23.417 10.5"
							className="cls-1"
							data-name="Path 226"
						/>
						<path
							id="Path_227"
							d="M33.917 39.333L19.5 24.917 33.917 10.5"
							className="cls-1"
							data-name="Path 227"
							transform="translate(5.683)"
						/>
					</g>
				</svg>
			);
			break;

		default:
			break;
	}

	return <div className={classNames(wrapInSquare && css.icon, className)}>{Content}</div>;
};

Icon.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string.isRequired,
	wrapInSquare: PropTypes.bool,
};

Icon.defaultProps = {
	className: '',
	wrapInSquare: false,
};

export default Icon;
