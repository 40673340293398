// @flow
export const GET_CARS = {
	START: 'GET_CARS.START',
	SUCCESS: 'GET_CARS.SUCCESS',
	FAIL: 'GET_CARS.FAIL',
};

export const GET_RESULTS = {
	START: 'GET_RESULTS.START',
	SUCCESS: 'GET_RESULTS.SUCCESS',
	FAIL: 'GET_RESULTS.FAIL',
};

export const GET_CAR = {
	START: 'GET_CAR.START',
	SUCCESS: 'GET_CAR.SUCCESS',
	FAIL: 'GET_CAR.FAIL',
};

export const CREATE_LEAD = {
	START: 'CREATE_LEAD.START',
	SUCCESS: 'CREATE_LEAD.SUCCESS',
	FAIL: 'CREATE_LEAD.FAIL',
};

export const CLEAR_CAR = 'CLEAR_CAR';
