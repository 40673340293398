// @flow
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import { Helmet } from 'react-helmet-async';
import { RATE_POPUP, VIDEO_POPUP } from 'client/constants';
import Header from 'client/components/common/Header';
import { Responsive } from 'client/components/common/Responsive';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import FooterBanner from 'client/components/common/FooterBanner';
import faviconImg from 'assets/favicon1.png';
import * as css from './Intro.scss';

import GeneralContentItem from './IntroContent/GeneralContentItem';
import MainItems from './IntroContent/MainItems';
import SingleItem from './IntroContent/SingleItem';
import TwoItemsVerOne from './IntroContent/TwoItemsVerOne';
import TwoItemsVerTwo from './IntroContent/TwoItemsVerTwo';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
	cars: Array<CarsItem>,
	settings: Settings,
	getClientItems: Function,
	setPopUp: Function,
	clearCar: Function,
|};

class Intro extends React.PureComponent<Props> {
	transitionCompleteTimeout: TimeoutID;

	static isBannerShown = false;

	static defaultProps = {
		className: '',
	};

	componentDidMount() {
		const { onTransitionComplete, getClientItems, clearCar, cars } = this.props;
		this.transitionCompleteTimeout = setTimeout(onTransitionComplete, parseInt(css.transitionIn, 10));
		if (_.isEmpty(cars)) {
			getClientItems();
		}
		clearCar();

		if (Responsive.isMatching(Responsive.DESKTOP)) {
			const { googletag } = window;
			const bannerID = 'div-gpt-ad-1615880607077-0';

			if (!window.SHOWN_BANNERS[bannerID]) {
				googletag.cmd.push(() => {
					googletag
						.defineSlot(
							'/6870/ynet/desktop/sargel/ypartner.cars',
							[
								[980, 50],
								[1920, 50],
							],
							bannerID
						)
						.addService(googletag.pubads());
					googletag.pubads().enableSingleRequest();
					googletag.enableServices();
				});

				window.SHOWN_BANNERS[bannerID] = true;
			}

			googletag.cmd.push(() => googletag.display(bannerID));
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	renderHelmet = () => {
		const { settings } = this.props;
		// eslint-disable-next-line max-len
		const title = _.get(settings.data, 'seo.title', ''); // _.get(settings.texts, 'seo.title', 'Template project — Client');
		// eslint-disable-next-line max-len
		const description = _.get(settings.data, 'seo.description', ''); // _.get(settings.texts, 'seo.description', 'Template project — Description');
		const ogTitle = ''; // _.get(settings.texts, 'seo.ogTitle', '');
		const ogDescription = ''; // _.get(settings.texts, 'seo.ogDescription', '');
		// const image = generalShareImg;
		const image = '';
		return (
			<Helmet
				title={title}
				link={[{ rel: 'icon', type: 'image/png', href: faviconImg }]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: ogTitle },
					{ property: 'og:description', content: ogDescription },
					{ property: 'og:image', content: image },
				]}
			/>
		);
	};

	onBtnRateClick = (e: SyntheticEvent<HTMLElement>) => {
		const { rate } = e.currentTarget.dataset;
		const { setPopUp, settings } = this.props;
		setPopUp(RATE_POPUP, {
			rate,
			rates: settings.data.filters.range,
			onClose: () => {
				setPopUp(null);
			},
		});
	};

	onBtnVideoClick = (e: SyntheticEvent<HTMLElement>) => {
		e.preventDefault();
		const { setPopUp } = this.props;
		const curSrc = e.currentTarget.getAttribute('data-src');

		setPopUp(VIDEO_POPUP, {
			videoSrc: curSrc,
			onClose: () => {
				setPopUp(null);
			},
		});
	};

	render() {
		const { className, transitionState, cars, settings } = this.props;
		const fourItemsData = _.slice(cars, 1, 5);
		const singleItem = cars[5];
		const twoItemsVerOneData = _.slice(cars, 6, 8);
		const twoItemsVerTwoData = _.slice(cars, 8, 10);
		const otherData = _.slice(cars, 10, cars.length);
		return (
			<div className={classNames(css.intro, css[transitionState], className)}>
				{this.renderHelmet()}
				<Header isFull />
				{cars[0] && (
					<GeneralContentItem
						item={cars[0]}
						rates={settings.data.filters.range}
						rateClick={this.onBtnRateClick}
						// videoBtnClick={this.onBtnVideoClick}
					/>
				)}
				{fourItemsData && (
					<MainItems
						className="single-item"
						mobileRangeType="medium"
						rates={settings.data.filters.range}
						rateClick={this.onBtnRateClick}
						items={fourItemsData}
					/>
				)}

				{singleItem && (
					<SingleItem
						item={singleItem}
						rates={settings.data.filters.range}
						rateClick={this.onBtnRateClick}
						videoBtnClick={this.onBtnVideoClick}
						mobileRangeType="mobile-small-type-one"
					/>
				)}

				{twoItemsVerOneData && twoItemsVerOneData.length > 0 && (
					<TwoItemsVerOne
						rates={settings.data.filters.range}
						rateClick={this.onBtnRateClick}
						items={twoItemsVerOneData}
						mobileRangeType="mobile-small-type-one"
					/>
				)}

				{twoItemsVerTwoData && twoItemsVerTwoData.length > 0 && (
					<TwoItemsVerTwo
						rates={settings.data.filters.range}
						rateClick={this.onBtnRateClick}
						items={twoItemsVerTwoData}
						mobileRangeType="mobile-small-type-one"
					/>
				)}
				{otherData && otherData.length > 0 && (
					<MainItems
						rates={settings.data.filters.range}
						rateClick={this.onBtnRateClick}
						items={otherData}
						mobileRangeType="mobile-small-type-two"
						withBanner
					/>
				)}
				<Responsive query={Responsive.DESKTOP}>
					<div className={css.footerBanner}>
						<div id="div-gpt-ad-1615880607077-0" />
					</div>
				</Responsive>
				<FooterBanner />
			</div>
		);
	}
}

export default withTransition(Intro);
