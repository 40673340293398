import { CAN_USE_DOM } from '../constants';

export const dataLayerEvents = {
	/**
	 * @param label {String}
	 */
	carItemClick: name =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'navigation_events',
			Category: 'Navigation',
			Action: 'Click to article',
			Label: name,
		}),
	searchResultTrue: name =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'navigation_events',
			Category: 'Search',
			Action: 'Click to Search',
			Label: name,
		}),
	searchResultFalse: name =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'navigation_events',
			Category: 'Search',
			Action: 'Click to Search',
			Label: `No results for ${name}`,
		}),
	onFormSubmit: name =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'sent_leads',
			Category: 'Leads',
			Action: 'Send_Leads',
			Label: `Leads_${name}`,
		}),
};
