// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { fileLink } from 'common/utils';
import { Responsive, GridBanner } from 'client/components/common';
import Range from 'client/components/common/Range';
import { CAR_ROUTE } from 'client/constants';
import { dataLayerEvents } from 'common/utils/gtag';

import * as css from './MainItems.scss';

type Props = {|
	item: CarsItem,
	rate: RangeType,
	rateClick: Function,
	className?: string,
	isBanner?: boolean,
	mobileRangeType: RangeItemType,
|};

class Item extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
		isBanner: false,
	};

	onClickLink = (e: SyntheticEvent<HTMLLinkElement>) => {
		const { name } = e.currentTarget.dataset;
		dataLayerEvents.carItemClick(name);
	};

	render() {
		const { rate, item, isBanner, rateClick, className, mobileRangeType } = this.props;
		const { color, range, title } = rate;
		const rangeType = Responsive.isMatching(Responsive.DESKTOP) ? 'small' : mobileRangeType;

		return (
			<div className={classNames(css.item, className)}>
				{isBanner ? (
					<GridBanner />
				) : (
					<>
						<Link
							data-name={item.name}
							to={`${CAR_ROUTE.replace(':id', item.id)}`}
							onClick={this.onClickLink}
						>
							<div
								className={css.imageWrapper}
								style={{ backgroundImage: `url(${fileLink(item.mainImage)})` }}
							></div>
						</Link>

						<div className={css.itemInfo}>
							<div className={css.info}>
								<div className={css.itemCategory}>
									<span>{item.category}</span>
								</div>
								<Link
									data-name={item.name}
									to={`${CAR_ROUTE.replace(':id', item.id)}`}
									onClick={this.onClickLink}
								>
									<div className={css.itemTitle}>{item.title}</div>
								</Link>
							</div>
							<div className={css.rate} data-rate={item.rate} onClick={rateClick}>
								<Range
									withAnimation={false}
									range={range}
									color={color}
									title={title}
									type={rangeType}
									onHoverAnimation
								/>
							</div>
						</div>
					</>
				)}
			</div>
		);
	}
}

export default Item;
