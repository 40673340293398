// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import Slider from 'react-slick';
import playBtn from 'assets/client/play-btn.svg';
import * as css from './CarSlider.scss';

type Props = {|
	className?: string,
	hasVideo?: boolean,
	totalMedia: Array<?string>,
	videoLink?: string,
	videoBtnClick: Function,
|};

type ReactObjRef = { current: HTMLDivElement | null };

class CarSlider extends React.PureComponent<Props> {
	sliderRef: ReactObjRef;

	static defaultProps = {
		className: '',
		hasVideo: false,
		videoLink: '',
	};

	constructor(props: Props) {
		super(props);

		this.sliderRef = React.createRef();
	}

	componentDidMount() {}

	componentDidUpdate(prevProps: Props) {}

	previousSlideHandler = () => {
		this.sliderRef.current.slickPrev();
	};

	nextSlideHandler = () => {
		this.sliderRef.current.slickNext();
	};

	render() {
		const { totalMedia, videoLink, videoBtnClick } = this.props;

		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			initialSlide: 0,
			arrows: false,
			// afterChange: index => {
			// 	if (index === 0) {
			// 		this.setState({
			// 			nextVisible: true,
			// 			prevVisible: false,
			// 		});
			// 	}

			// 	if (index === totalMedia.length - 1) {
			// 		this.setState({
			// 			prevVisible: true,
			// 			nextVisible: false,
			// 		});
			// 	}
			// },
			// loop: true,
		};
		return (
			!_.isEmpty(totalMedia) && (
				<div className={css.sliderWrapper}>
					<Slider {...settings} className={css.carSlider} ref={this.sliderRef}>
						{_.map(totalMedia, (i, index: number) => {
							return index === totalMedia.length - 1 && videoLink ? (
								<div className={css.slide} key={i}>
									<div className={css.slideInner} style={{ backgroundImage: `url(${i || ''})` }}>
										<div className={css.playBtn} onClick={videoBtnClick}>
											<img src={playBtn} alt="" />
										</div>
									</div>
								</div>
							) : (
								<div className={css.slide} key={i}>
									<div
										className={css.slideInner}
										style={{ backgroundImage: `url(${i || ''})` }}
									></div>
								</div>
							);
						})}
					</Slider>
					<div className={css.customButtons}>
						<button
							className={classNames(css.nextBtn)}
							type="button"
							onClick={this.nextSlideHandler}
						></button>
						<button
							className={classNames(css.prevBtn)}
							type="button"
							onClick={this.previousSlideHandler}
						></button>
					</div>
				</div>
			)
		);
	}
}

export default CarSlider;
