// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

type Results = {
	isBusy: boolean,
	items: Array<CarsItem>,
	similar: Array<CarsItem>,
	total: number,
};

const initialState: Results = {
	isBusy: false,
	items: [],
	similar: [],
	total: 0,
};

type Action =
	| { type: typeof actions.GET_RESULTS.START, payload: void }
	| { type: typeof actions.GET_RESULTS.SUCCESS, payload: Results }
	| { type: typeof actions.GET_RESULTS.FAIL, payload: void };

export default function settingsReducer(state: Results = initialState, action: Action) {
	return produce<any>(state, (draft: Results) => {
		switch (action.type) {
			case actions.GET_RESULTS.START:
				draft.isBusy = true;
				break;

			case actions.GET_RESULTS.SUCCESS:
				draft.items = action.payload.items;
				draft.similar = action.payload.similar;
				draft.isBusy = false;
				break;

			case actions.GET_RESULTS.FAIL:
				draft.isBusy = false;
				break;

			default:
				return state;
		}
	});
}
