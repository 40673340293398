// @flow
/* eslint-disable class-methods-use-this */
import * as React from 'react';

import { isIOS } from 'common/utils';
import { Responsive } from 'client/components/common/Responsive';

import * as css from './GridBanner.scss';

type Props = {||};

export class GridBanner extends React.PureComponent<Props> {
	componentDidMount() {
		const { googletag } = window;

		if (!window.SHOWN_BANNERS[this.bannerID]) {
			googletag.cmd.push(() => {
				googletag.defineSlot(this.slotName, ['fluid'], this.bannerID).addService(googletag.pubads());
				googletag.pubads().enableSingleRequest();
				googletag.enableServices();
			});

			window.SHOWN_BANNERS[this.bannerID] = true;
		}

		googletag.cmd.push(() => googletag.display(this.bannerID));
	}

	get slotName() {
		if (Responsive.isMatching(Responsive.DESKTOP)) return '/6870/ynet/desktop/native.ad/ypartner.cars';
		if (isIOS()) return '/6870/ynet/mobileweb_iphone/native.ad/ypartner.cars';

		return '/6870/ynet/mobileweb_android/native.ad/ypartner.cars';
	}

	get bannerID() {
		if (Responsive.isMatching(Responsive.DESKTOP)) return 'div-gpt-ad-1618390454736-0';
		if (isIOS()) return 'div-gpt-ad-1618390689185-0';
		return 'div-gpt-ad-1618390585278-0';
	}

	render() {
		return (
			<div className={css.gridBanner}>
				<div id={this.bannerID} />
			</div>
		);
	}
}
