// @flow

import { CAN_USE_DOM } from 'common/constants';

export function isIOS() {
	if (CAN_USE_DOM) {
		const ua = window.navigator.userAgent;
		return !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
	}

	return false;
}
