// @flow
import * as React from 'react';
import _ from 'lodash';

import Item from './Item';

import * as css from './MainItems.scss';

type Props = {|
	items: Array<CarsItem>,
	rates: Array<RangeType>,
	rateClick: Function,
	className?: string,
	mobileRangeType: RangeItemType,
	withBanner?: boolean,
|};

class FourItems extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
		withBanner: false,
	};

	render() {
		const { items, withBanner = false, rates, rateClick, className, mobileRangeType } = this.props;

		return (
			<div className={css.items}>
				<div className={css.itemsI}>
					{_.map(items, (i, index) => {
						const neededRateInfo: any = _.find<RangeType>(rates, { range: i.rate });

						return (
							<React.Fragment key={index === 11 && withBanner ? 'grid-banner-item' : i.createdDate}>
								{index === 11 && withBanner && (
									<Item
										rate={neededRateInfo}
										item={i}
										isBanner
										rateClick={rateClick}
										className={className}
										mobileRangeType={mobileRangeType}
									/>
								)}
								<Item
									rate={neededRateInfo}
									item={i}
									rateClick={rateClick}
									className={className}
									mobileRangeType={mobileRangeType}
								/>
							</React.Fragment>
						);
					})}
				</div>
			</div>
		);
	}
}

export default FourItems;
