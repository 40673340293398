// @flow
/* eslint-disable max-len */
import * as React from 'react';
import _ from 'lodash';

import Range from '../../../Range';
import * as css from './Rate.scss';

type Props = {
	onClose: Function,
	rate: string,
	rates: Array<RangeType>,
};

export default class Rate extends React.PureComponent<Props> {
	componentDidMount() {}
	closePopup = (e: SyntheticEvent<HTMLElement>) => {
		e.preventDefault();
		const { onClose } = this.props;
		onClose();
	};
	render() {
		const { rate, rates } = this.props;
		const neededRate: any = _.find(rates, item => item.range === rate);
		const { title, description, range, color } = neededRate;

		return (
			<div className={css.ratePopUp}>
				<div className={css.content}>
					<a href="#" onClick={this.closePopup} className={css.closeBtn}></a>
					<div className={css.contentWrapper}>
						<div className={css.infoBlock}>
							<div className={css.infoBlockInner}>
								<h4 style={{ color }}>{title}</h4>
								<div className={css.description}>
									<p dangerouslySetInnerHTML={{ __html: description }} />
								</div>
							</div>
						</div>
						<div className={css.rateWrapper}>
							<Range withAnimation range={range} color={color} type="big" inPopupAnimation isPopUp />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
