// @flow
import { combineReducers } from 'redux';
import type { Reducer, Action } from 'redux';

import settings from './settings';
import cars from './cars';
import results from './results';
import popUp from './pop-up';
import car from './car';
import search from './search';
import userFilters from './user-filters';

const reducer: Reducer<ClientStore, Action<{ type: string, payload: any }>> = combineReducers({
	cars,
	results,
	settings,
	popUp,
	car,
	search,
	userFilters,
});

export default reducer;
