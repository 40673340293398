// @flow
/* eslint-disable class-methods-use-this */
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet-async';
// import _ from 'lodash';

import { detectIE } from 'common/utils';

import { ROOT_ROUTE, SEARCH_ROUTE, CAR_ROUTE } from 'client/constants';
import Intro from 'client/components/pages/Intro';
import CarPage from 'client/components/pages/CarPage';
import SearchPage from 'client/components/pages/SearchPage';
import CarIframePage from 'client/components/pages/CarIframePage';

import faviconImg from 'assets/favicon1.png';
import generalShareImg from 'assets/client/share.jpg';
import { PopUpManager } from './common/PopUpManager';
import * as css from './App.scss';
import { CAR_IFRAME_ROUTE } from '../constants/routes';

type Props = {|
	settings: Settings,
	history: RouterHistory,
	location: RouterLocation,
	isBusy?: boolean,
|};

type State = {
	prevPath?: string,
};

class App extends React.PureComponent<Props, State> {
	static defaultProps = {
		isBusy: true,
	};

	state = {
		prevPath: ROOT_ROUTE,
	};

	async componentDidMount() {
		const isIE = detectIE();

		// tracker.init('UA-142761482-39');
		// this.props.checkAuthState();

		if (!this.props.isBusy) {
			this.removeRootPreloader();
		}

		if (document && document.body) {
			document.body.setAttribute('data-browser', isIE ? 'ie' : 'not-ie');
		}
	}

	async componentDidUpdate(prevProps: Props) {
		if (!this.props.isBusy && prevProps.isBusy) {
			this.removeRootPreloader();
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.location.pathname !== prevState.prevPath && nextProps.location.pathname !== SEARCH_ROUTE) {
			return { prevPath: nextProps.location.pathname };
		}
		return null;
	}

	renderHelmet = () => {
		// const { settings } = this.props;
		const title = 'Template project — Client'; // _.get(settings.texts, 'seo.title', 'Template project — Client');
		// eslint-disable-next-line max-len
		const description = 'Template project — Description'; // _.get(settings.texts, 'seo.description', 'Template project — Description');
		const ogTitle = ''; // _.get(settings.texts, 'seo.ogTitle', '');
		const ogDescription = ''; // _.get(settings.texts, 'seo.ogDescription', '');
		const image = generalShareImg;
		return (
			<Helmet
				title={title}
				link={[{ rel: 'icon', type: 'image/png', href: faviconImg }]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: ogTitle },
					{ property: 'og:description', content: ogDescription },
					{ property: 'og:image', content: image },
				]}
			/>
		);
	};

	removeRootPreloader = () => {
		const preloadingScreen: ?HTMLElement = document.querySelector('.preloading-screen');

		if (preloadingScreen) {
			preloadingScreen.addEventListener('transitionend', (e: TransitionEvent) => {
				setTimeout(() => {
					if (document && document.body && e.target === preloadingScreen) {
						document.body.removeChild(preloadingScreen);
					}
				}, 1000);
			});
			preloadingScreen.style.opacity = '0';
			preloadingScreen.style.zIndex = '-1000';
		}
	};

	renderRoute = (Component: Function) => {
		return ({ match, ...props }) => {
			return <Component {...props} in={match !== null} />;
		};
	};

	render() {
		// const { location } = this.props;
		// const url = location.pathname + location.search;

		return (
			<div id="app" className={css.app}>
				{/* {this.renderHelmet()} */}
				<div className={css.content}>
					<Route exact path={ROOT_ROUTE}>
						{({ match }) => <Intro in={!!match} />}
					</Route>
					<Route exact path={SEARCH_ROUTE}>
						{props => <SearchPage {...props} prevPath={this.state.prevPath} in={!!props.match} />}
					</Route>
					<Route exact path={CAR_ROUTE}>
						{props => <CarPage {...props} in={!!props.match} />}
					</Route>
					<Route exact path={CAR_IFRAME_ROUTE}>
						{props => <CarIframePage {...props} in={!!props.match} />}
					</Route>
				</div>
				<PopUpManager />
			</div>
		);
	}
}

const mapState = (state: ClientStore) => ({
	isBusy: state.settings.isBusy, // && state.cars.isBusy,
	settings: state.settings,
});

const mapDispatch = {};

export default withRouter(connect(mapState, mapDispatch)(App));
