// @flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Responsive } from 'client/components/common';
import _ from 'lodash';

import { SEARCH_ROUTE } from 'client/constants';
import { Icon } from 'common/components';
import * as css from './Search.scss';

type Props = {
	className?: string,
	history: RouterHistory,
	location: RouterLocation,
	onSearch: Function,
	autocompleteArray: Array<string>,
};

type State = {
	isFocused: boolean,
	show: boolean,
	searchValue: string,
	sugestionsList: Array<string>,
};

class Search extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
	};

	state = {
		isFocused: false,
		searchValue: '',
		show: false,
		sugestionsList: [],
	};

	componentDidMount() {
		if (Responsive.isMatching(Responsive.DESKTOP)) {
			setTimeout(() => {
				this.setState({ show: true });
			}, 10);
		} else {
			this.setState({ show: true });
		}
	}

	onChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		// TODO: validate search string
		this.setState({ searchValue: value });
		if (value.length > 0) {
			const filteredItems = this.props.autocompleteArray.filter(i => {
				const item = i.toLowerCase();
				const suggest = value.toLowerCase();
				return item.includes(suggest);
			});

			this.setState({ sugestionsList: filteredItems });
		} else {
			this.setState({ sugestionsList: [] });
		}
	};

	highlightWrapper = (text: string, word: string) => {
		const index = text.indexOf(word);

		if (index >= 0 && word.length > 0) {
			return (
				<div>
					<span>{text.substring(0, index)}</span>
					<span className={css.highlight}>{text.substring(index, index + word.length)}</span>
					<span>{text.substring(index + word.length)}</span>
				</div>
			);
		}
		return text;
	};

	onFocus = (e: SyntheticInputEvent<HTMLInputElement>) => {
		this.setState({ isFocused: true });
	};

	onBlur = (e: SyntheticInputEvent<HTMLInputElement>) => {
		this.setState({ isFocused: false });
	};

	handleOnSubmit = (e: SyntheticEvent<>) => {
		e.preventDefault();

		this.search(this.state.searchValue);
	};

	handleSuggestClick = (e: SyntheticEvent<HTMLDivElement>) => {
		const { searchString } = e.currentTarget.dataset;

		this.search(searchString);
	};

	search = (value: string) => {
		const { history, location, onSearch } = this.props;

		onSearch(value);
		this.setState({ searchValue: '', sugestionsList: [] });

		if (location.pathname !== SEARCH_ROUTE && value !== '') {
			history.push(SEARCH_ROUTE);
		}
	};

	render() {
		const { className } = this.props;
		const { isFocused, searchValue, show, sugestionsList } = this.state;
		const placeholder = 'יצרן';

		return (
			<form className={classNames(css.searchForm, className, show && css.show)} onSubmit={this.handleOnSubmit}>
				<div className={css.searchField}>
					<input
						className={classNames(css.input)}
						type="text"
						value={searchValue}
						placeholder={placeholder}
						onChange={this.onChange}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
					/>

					<Icon type="search" className={classNames(css.searchIcon, !isFocused && css.show)} />
					<button type="submit">
						<Icon type="arrowLeft" className={classNames(css.arrowLeftIcon, isFocused && css.show)} />
					</button>
					{!_.isEmpty(sugestionsList) && (
						<div className={css.sugestions}>
							<div className={css.sugestionsList}>
								{sugestionsList.map(item => {
									return (
										<div
											className={css.sugestionItem}
											key={item}
											data-search-string={item}
											onClick={this.handleSuggestClick}
										>
											{this.highlightWrapper(item, searchValue)}
										</div>
									);
								})}
							</div>
						</div>
					)}
				</div>
			</form>
		);
	}
}

export default withRouter(Search);
