// @flow

import * as React from 'react';
import classNames from 'classnames';

import * as css from './Footer.scss';

type Props = {
	className?: string,
	children?: React.Node,
};

const Footer = ({ className, children }: Props) => {
	return <div className={classNames(css.footer, className)}>{children}</div>;
};

Footer.defaultProps = {
	className: '',
	children: <div />,
};

export default Footer;
