// @flow
import * as React from 'react';
import { fileLink } from 'common/utils';
import { CAR_ROUTE } from 'client/constants';
import { Link } from 'react-router-dom';
import { Responsive } from 'client/components/common';
import Range from 'client/components/common/Range';
import { dataLayerEvents } from 'common/utils/gtag';
import * as css from './TwoItemsVerTwo.scss';

type Props = {
	item: CarsItem,
	rate: RangeType,
	rateClick: Function,
	mobileRangeType: RangeItemType,
};

class Item extends React.PureComponent<Props> {
	onClickLink = (e: SyntheticEvent<HTMLLinkElement>) => {
		const { name } = e.currentTarget.dataset;
		dataLayerEvents.carItemClick(name);
	};

	render() {
		const { item, rate, rateClick, mobileRangeType } = this.props;
		const { color, title, range } = rate;
		const rangeType = Responsive.isMatching(Responsive.DESKTOP) ? 'small' : mobileRangeType;

		return (
			<div className={css.item}>
				<Link
					data-name={item.name}
					to={`${CAR_ROUTE.replace(':id', item.id)}`}
					onClick={this.onClickLink}
					className={css.mediaWrapper}
				>
					<div className={css.media} style={{ backgroundImage: `url(${fileLink(item.mainImage)})` }}></div>
				</Link>
				<div className={css.itemInfo}>
					<div className={css.info}>
						<div className={css.itemCategory}>
							<span>{item.category}</span>
						</div>
						<Link
							data-name={item.name}
							to={`${CAR_ROUTE.replace(':id', item.id)}`}
							onClick={this.onClickLink}
						>
							<div className={css.itemTitle}>{item.title}</div>
						</Link>
					</div>
					<div className={css.rate} data-rate={item.rate} onClick={rateClick}>
						<Range
							withAnimation={false}
							color={color}
							title={title}
							range={range}
							type={rangeType}
							onHoverAnimation
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default Item;
