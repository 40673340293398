// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import { Responsive } from 'client/components/common';
import Range from 'client/components/common/Range';
import * as css from './RateTags.scss';

type Props = {|
	goodTags?: string,
	badTags?: string,
	normalTags?: string,
	rate: string,
	color: string,
	title?: string,
	rateClick: Function,
	price: number,
|};

class RateTags extends React.PureComponent<Props> {
	static defaultProps = {
		goodTags: '',
		badTags: '',
		normalTags: '',
		title: '',
	};

	componentDidMount() {}

	componentDidUpdate(prevProps: Props) {}

	render() {
		const { goodTags, badTags, normalTags, rate, color, title, rateClick, price } = this.props;
		return (
			<div className={css.rateTags}>
				<div className={css.tags}>
					{goodTags && (
						<p>
							<span>טוב:</span>
							{goodTags}
						</p>
					)}
					{normalTags && (
						<p>
							<span>בסדר:</span>
							{normalTags}
						</p>
					)}
					{badTags && (
						<p>
							<span>טעון שיפור: </span>
							{badTags}
						</p>
					)}

					<p>
						<span>מחיר:</span>
						{`החל מ-${price} שקל`}
					</p>
				</div>
				{Responsive.isMatching(Responsive.DESKTOP) && rate && (
					<div className={css.rate} data-rate={rate} onClick={rateClick}>
						<Range withAnimation color={color} range={rate} type="medium" title={title} onHoverAnimation />
					</div>
				)}
			</div>
		);
	}
}

export default RateTags;
