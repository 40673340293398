// @flow
import { connect } from 'react-redux';
import { setPopUp, getCar } from 'client/actions';
import CarIframePage from './CarIframePage';

const mapState = (state: ClientStore) => ({
	settings: state.settings,
	car: state.car.item,
});
const mapDispatch = {
	setPopUp,
	getCar,
};

export default connect(mapState, mapDispatch)(CarIframePage);
