// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import * as css from './RateTags.scss';

type Props = {|
	goodTags?: string,
	badTags?: string,
	normalTags?: string,
	rate: string,
|};

class RateTags extends React.PureComponent<Props> {
	static defaultProps = {
		goodTags: '',
		badTags: '',
		normalTags: '',
	};

	componentDidMount() {}

	componentDidUpdate(prevProps: Props) {}

	render() {
		const { goodTags, badTags, normalTags, rate } = this.props;
		return (
			<div className={css.rateTags}>
				<div className={css.tags}>
					{goodTags && (
						<p>
							<span>טוב:</span>
							{goodTags}
						</p>
					)}
					{normalTags && (
						<p>
							<span>בסדר:</span>
							{normalTags}
						</p>
					)}
					{badTags && (
						<p>
							<span>טעון שיפור: </span>
							{badTags}
						</p>
					)}
					<p>
						<span>ציון:</span>
						{`${rate}/10`}
					</p>
				</div>
			</div>
		);
	}
}

export default RateTags;
