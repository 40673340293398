// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

type UserFilters = [];

const initialState: UserFilters = [];

type Action = { type: typeof actions.SET_FILTERS, payload: string };

export default function popUpReducer(state: UserFilters = initialState, action: Action) {
	return produce<any>(state, (draft: UserFilters) => {
		switch (action.type) {
			case actions.SET_FILTERS:
				return action.payload;

			default:
				break;
		}
	});
}
