// @flow
/* eslint-disable class-methods-use-this */
import * as React from 'react';

import { RATE_POPUP, VIDEO_POPUP } from 'client/constants';
import PopUp from './PopUp';
import Rate from './Content/Rate';
import VideoPopUp from './Content/VideoPopUp';

type Props = {
	popUp?: {
		id: string,
		data?: any,
	},
};

type State = {
	popUp: {
		id?: string,
		data?: any,
		visible: boolean,
	},
};

/*
 * This is very basic implementation,
 * now it allow use single <PopUp> component for all modal pop-ups of the application
 *
 * TODO: implement pop-up opening from visible pop-up
 */
export default class PopUpManager extends React.Component<Props, State> {
	static defaultProps = {
		popUp: {},
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			popUp: { ...props.popUp, visible: props.popUp ? !!this.popUpContentMap[props.popUp.id] : false },
		};
	}

	static getDerivedStateFromProps(props: Props, state: State) {
		if (props.popUp && props.popUp.id) {
			return {
				popUp: { ...props.popUp, visible: true },
			};
		}

		if (props.popUp && !props.popUp.id && state.popUp.id) {
			return {
				popUp: { ...state.popUp, visible: false },
			};
		}

		return null;
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		const {
			popUp: { visible },
		} = this.state;

		if (!prevState.popUp.visible && visible) {
			document
				.getElementsByTagName('body')[0]
				.setAttribute('style', 'overflow: hidden; height: 100vh; max-height: 100vh');
		} else if (prevState.popUp.visible && !visible) {
			document.getElementsByTagName('body')[0].removeAttribute('style');
		}
	}

	get popUpContentMap(): { [id: string]: React.AbstractComponent<any> } {
		return {
			[RATE_POPUP]: Rate,
			[VIDEO_POPUP]: VideoPopUp,
		};
	}

	render() {
		const { popUp } = this.state;
		const PopUpContent = this.popUpContentMap[popUp?.id || 'none'];
		const isContentAvailable = !!PopUpContent;

		return (
			<PopUp
				content={isContentAvailable ? <PopUpContent {...popUp.data} /> : null}
				visible={popUp.visible && isContentAvailable}
			/>
		);
	}
}
