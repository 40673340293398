// @flow

import { GET_DEFAULT_RESULTS_PARAMS } from 'client/constants';
import * as actions from 'client/constants/actions';
import * as api from 'api';

import { apiRequestAction } from './helpers/api-request';

export function getClientItems(params: GetItemsParams<>): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.GET_CARS,
			request: api.getClientItems,
			params: { ...params },
		});

		return dispatch(requestAction);
	};
}

export function getResults(params: GetItemsParams<>): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.GET_RESULTS,
			request: api.getResults,
			params: { ...GET_DEFAULT_RESULTS_PARAMS, ...params },
		});

		return dispatch(requestAction);
	};
}

export function getCar(params: GetItemParams): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.GET_CAR,
			request: api.getClientItem,
			params: { ...params },
		});

		return dispatch(requestAction);
	};
}

export function createLead(params: CreateLeadParams): any {
	return async (dispatch: Dispatch) => {
		const requestAction = apiRequestAction({
			action: actions.CREATE_LEAD,
			request: api.createLead,
			params: { ...params },
		});

		return dispatch(requestAction);
	};
}

export function clearCar(): any {
	return {
		type: actions.CLEAR_CAR,
	};
}
