// @flow
/* eslint-disable max-len */
import * as React from 'react';
import classNames from 'classnames';
import arcDescribe from 'client/utils/arcDescribe';

import * as css from './Range.scss';

type Props = {
	range: string,
	color: string,
	title?: string,
	withAnimation?: boolean,
	type?: RangeItemType,
	onHoverAnimation?: boolean,
	isPopUp?: boolean,
};

type State = {
	show: boolean,
	animationPlay: boolean,
	popupOverlayShow: boolean,
};

export default class Range extends React.PureComponent<Props, State> {
	pathRef: { current: any };
	static defaultProps = {
		withAnimation: false,
		type: 'small',
		title: '',
		onHoverAnimation: false,
		isPopUp: false,
	};
	constructor() {
		super();
		this.pathRef = React.createRef();
	}
	state = {
		show: false,
		animationPlay: false,
		popupOverlayShow: true,
	};

	componentDidMount() {
		const { withAnimation, range } = this.props;
		if (withAnimation) {
			const path = this.pathRef.current;
			path.setAttribute('d', arcDescribe(83, 83, 72, -95, 190));
			this.drawArc(path, range);
		} else {
			this.setState({
				show: true,
			});
		}
	}

	drawArc = (path: any, range: string) => {
		let i: number = 190;
		let finishIterationStart = false;
		let finishAngle = null;
		switch (range) {
			case '0-5':
				finishAngle = 39;
				break;
			case '6':
				finishAngle = 13;
				break;
			case '7':
				finishAngle = -14;
				break;
			case '8':
				finishAngle = -38;
				break;
			case '9':
				finishAngle = -68;
				break;
			case '10':
				finishAngle = -90;
				break;
			default:
				break;
		}

		const m = setInterval(() => {
			if (i >= -95 && finishIterationStart) {
				// eslint-disable-next-line no-plusplus
				i++;
				if (i === finishAngle) {
					clearInterval((m: Function));
					this.setState({
						show: true,
						animationPlay: false,
						popupOverlayShow: false,
					});
					return;
				}
			}

			if (i === -95 && !finishIterationStart) {
				finishIterationStart = true;
			}

			if (!finishIterationStart) {
				// eslint-disable-next-line no-plusplus
				i--;
			}

			path.setAttribute('d', arcDescribe(83, 83, 72, -95, i));
		}, 4);
	};

	onHover = () => {
		const { range, onHoverAnimation } = this.props;
		const { animationPlay } = this.state;
		if (animationPlay || !onHoverAnimation) return;
		this.setState(
			{
				show: false,
				animationPlay: true,
				popupOverlayShow: true,
			},
			() => {
				const path = this.pathRef.current;
				path.setAttribute('d', arcDescribe(83, 83, 72, -95, 190));
				this.drawArc(path, range);
			}
		);
	};

	render() {
		// const neededRate: any = _.find(rates, item => item.range === rate);
		// const { title, description, range, color } = neededRate;
		const { range, color, title, type, isPopUp } = this.props;
		const { show, popupOverlayShow } = this.state;

		const rangeNumber =
			// eslint-disable-next-line no-nested-ternary
			range === '0-5' ? 5 : range === '6' ? 6 : range === '7' ? 7 : range === '8' ? 8 : range === '9' ? 9 : 10;
		return (
			<div className={classNames(css.rateBlock, css[type], isPopUp && css.popUpRate)} onMouseEnter={this.onHover}>
				{/* <div className={css.rateImage}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="165.119"
						height="165.077"
						viewBox="0 0 165.119 165.077"
						className={css.rateSvg}
					>
						<g id="ציון_אייטם_ראשי" data-name="ציון אייטם ראשי" transform="translate(0.512)">
							<g id="Group_266" data-name="Group 266" transform="translate(-866.365 -429.395)">
								<path
									className={classNames(css.path, range !== '0-5' && show && css.hide)}
									id="Union_5"
									data-name="Union 5"
									d="M4359.678-243.893a82.544,82.544,0,0,1-29.814-36.2,82.177,82.177,0,0,1,7.589-78.037,82.5,82.5,0,0,1,12.448-14.471l12.588,15.161a62.467,62.467,0,0,0-19.29,49.305,62.613,62.613,0,0,0,62.487,58.622v.006h.035a9.838,9.838,0,0,1,9.837,9.837,9.837,9.837,0,0,1-9.837,9.836h-.168A81.911,81.911,0,0,1,4359.678-243.893Z"
									transform="translate(-3457 823.81)"
									fill="#002adb"
									stroke="rgba(0,0,0,0)"
									strokeMiterlimit="10"
									strokeWidth="1"
								/>
								<g id="Group_265" data-name="Group 265" transform="translate(866.365 429.395)">
									<path
										className={classNames(css.path, range !== '10' && show && css.hide)}
										id="Path_210"
										data-name="Path 210"
										d="M1035.348,520.919h19.676c0-1.522-.04-3.071-.126-4.61a82.7,82.7,0,0,0-4.87-23.4l-18.238,7.259A62.569,62.569,0,0,1,1035.348,520.919Z"
										transform="translate(-890.417 -438.63)"
										fill="#f05"
									/>
									<path
										className={classNames(css.path, range !== '9' && show && css.hide)}
										id="Path_211"
										data-name="Path 211"
										d="M1025.688,486.86l18.29-7.28a82.871,82.871,0,0,0-17.875-24.225l-12.969,14.762A62.871,62.871,0,0,1,1025.688,486.86Z"
										transform="translate(-887.704 -433.169)"
										fill="#fead11"
									/>
									<path
										className={classNames(css.path, range !== '8' && show && css.hide)}
										id="Path_212"
										data-name="Path 212"
										d="M983.9,452.323a62.44,62.44,0,0,1,18.406,9.12l13.015-14.816a82.75,82.75,0,0,0-14.713-8.92,80.793,80.793,0,0,0-11.842-4.474Z"
										transform="translate(-883.454 -429.953)"
										fill="#fc75a9"
									/>
									<path
										className={classNames(css.path, range !== '6' && show && css.hide)}
										id="Path_215"
										data-name="Path 215"
										d="M917.693,461.464a62.252,62.252,0,0,1,17.878-8.966l-4.341-19.258A81.562,81.562,0,0,0,905.1,446.3Z"
										transform="translate(-871.997 -429.954)"
										fill="#6731ff"
									/>
									<path
										className={classNames(css.path, range !== '7' && show && css.hide)}
										id="Path_216"
										data-name="Path 216"
										d="M949.742,449.941a63.385,63.385,0,0,1,6.442-.744c1.326-.082,2.67-.124,3.992-.124a63.063,63.063,0,0,1,9.8.779l4.878-19.138a80.69,80.69,0,0,0-8.739-1.109c-1.972-.138-3.966-.209-5.932-.209a81.8,81.8,0,0,0-14.77,1.32Z"
										transform="translate(-877.857 -429.395)"
										fill="#00c4ce"
									/>
								</g>
							</g>
						</g>
					</svg>
				</div> */}
				<div className={css.rateImage}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="165.119"
						height="165.077"
						viewBox="0 0 165.119 165.077"
						className={css.rateSvg}
					>
						<g id="ציון_אייטם_ראשי" data-name="ציון אייטם ראשי" transform="translate(0.512)">
							<g id="Group_266" data-name="Group 266" transform="translate(-866.365 -429.395)">
								<path
									className={classNames(css.path)}
									id="Union_5"
									data-name="Union 5"
									d="M4359.678-243.893a82.544,82.544,0,0,1-29.814-36.2,82.177,82.177,0,0,1,7.589-78.037,82.5,82.5,0,0,1,12.448-14.471l12.588,15.161a62.467,62.467,0,0,0-19.29,49.305,62.613,62.613,0,0,0,62.487,58.622v.006h.035a9.838,9.838,0,0,1,9.837,9.837,9.837,9.837,0,0,1-9.837,9.836h-.168A81.911,81.911,0,0,1,4359.678-243.893Z"
									transform="translate(-3457 823.81)"
									fill="#002adb"
									stroke="rgba(0,0,0,0)"
									strokeMiterlimit="10"
									strokeWidth="1"
								/>
								<g id="Group_265" data-name="Group 265" transform="translate(866.365 429.395)">
									<path
										className={classNames(css.path, rangeNumber < 10 && show && css.hide)}
										id="Path_210"
										data-name="Path 210"
										d="M1035.348,520.919h19.676c0-1.522-.04-3.071-.126-4.61a82.7,82.7,0,0,0-4.87-23.4l-18.238,7.259A62.569,62.569,0,0,1,1035.348,520.919Z"
										transform="translate(-890.417 -438.63)"
										fill="#f05"
									/>
									<path
										className={classNames(css.path, rangeNumber < 9 && show && css.hide)}
										id="Path_211"
										data-name="Path 211"
										d="M1025.688,486.86l18.29-7.28a82.871,82.871,0,0,0-17.875-24.225l-12.969,14.762A62.871,62.871,0,0,1,1025.688,486.86Z"
										transform="translate(-887.704 -433.169)"
										fill="#fead11"
									/>
									<path
										className={classNames(css.path, rangeNumber < 8 && show && css.hide)}
										id="Path_212"
										data-name="Path 212"
										d="M983.9,452.323a62.44,62.44,0,0,1,18.406,9.12l13.015-14.816a82.75,82.75,0,0,0-14.713-8.92,80.793,80.793,0,0,0-11.842-4.474Z"
										transform="translate(-883.454 -429.953)"
										fill="#fc75a9"
									/>
									<path
										className={classNames(css.path, rangeNumber < 6 && show && css.hide)}
										id="Path_215"
										data-name="Path 215"
										d="M917.693,461.464a62.252,62.252,0,0,1,17.878-8.966l-4.341-19.258A81.562,81.562,0,0,0,905.1,446.3Z"
										transform="translate(-871.997 -429.954)"
										fill="#6731ff"
									/>
									<path
										className={classNames(css.path, rangeNumber < 7 && show && css.hide)}
										id="Path_216"
										data-name="Path 216"
										d="M949.742,449.941a63.385,63.385,0,0,1,6.442-.744c1.326-.082,2.67-.124,3.992-.124a63.063,63.063,0,0,1,9.8.779l4.878-19.138a80.69,80.69,0,0,0-8.739-1.109c-1.972-.138-3.966-.209-5.932-.209a81.8,81.8,0,0,0-14.77,1.32Z"
										transform="translate(-877.857 -429.395)"
										fill="#00c4ce"
									/>
								</g>
							</g>
						</g>
					</svg>
				</div>
				<div className={classNames(css.rateNumber, show && css.show)} style={{ color }}>
					{range}
				</div>
				<div className={classNames(css.rateTitle, show && css.show)} style={{ color }}>
					{title}
				</div>
				<div className={classNames(css.svgOverlay, !popupOverlayShow && css.hideOverlay)}>
					<svg width="166" height="166" viewBox="0 0 166 166">
						<path ref={this.pathRef} id="arc1" fill="none" stroke="#fff" strokeWidth="28" />
					</svg>
				</div>
			</div>
		);
	}
}
