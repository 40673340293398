// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import faviconImg from 'assets/favicon1.png';
import { Helmet } from 'react-helmet-async';
import { ROOT_ROUTE, RATE_POPUP, VIDEO_POPUP } from 'client/constants';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import FooterBanner from 'client/components/common/FooterBanner';
import Header from 'client/components/common/Header';
import Filters from 'client/components/common/Filters';
import { Breadcrumbs, Responsive } from 'client/components/common';
import noMatchImage from 'assets/client/no-match.svg';
import { dataLayerEvents } from 'common/utils/gtag';
import ResultItem from './ResultItem';
import SimilarItem from './SimilarItem';

import * as css from './SearchPage.scss';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
	results: Array<CarsItem>,
	similar: Array<CarsItem>,
	settings: Settings,
	setPopUp: Function,
	prevPath: string,
	car?: CarsItem | any,
	searchPhrase?: string,
	isBusy: boolean,
	userFilters: any,
|};

type State = {
	openFilters: boolean,
};

class SearchPage extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
		searchPhrase: '',
		car: {},
	};

	state = {
		openFilters: false,
	};

	async componentDidMount() {
		const { onTransitionComplete } = this.props;
		this.transitionCompleteTimeout = setTimeout(onTransitionComplete, parseInt(css.transitionIn, 10));
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}

		if (!_.isEqual(this.props.results, prevProps.results) && !_.isEmpty(this.props.results)) {
			dataLayerEvents.searchResultTrue(this.props.searchPhrase);
		}
		if (!_.isEqual(this.props.results, prevProps.results) && _.isEmpty(this.props.results)) {
			dataLayerEvents.searchResultFalse(this.props.searchPhrase);
		}
	}

	renderHelmet = () => {
		const { settings } = this.props;
		// eslint-disable-next-line max-len
		const title = _.get(settings.data, 'seo.title', ''); // _.get(settings.texts, 'seo.title', 'Template project — Client');
		// eslint-disable-next-line max-len
		const description = _.get(settings.data, 'seo.description', ''); // _.get(settings.texts, 'seo.description', 'Template project — Description');
		const ogTitle = ''; // _.get(settings.texts, 'seo.ogTitle', '');
		const ogDescription = ''; // _.get(settings.texts, 'seo.ogDescription', '');
		// const image = generalShareImg;
		const image = '';
		return (
			<Helmet
				title={title}
				link={[{ rel: 'icon', type: 'image/png', href: faviconImg }]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: ogTitle },
					{ property: 'og:description', content: ogDescription },
					{ property: 'og:image', content: image },
				]}
			/>
		);
	};

	onBtnRateClick = (e: SyntheticEvent<HTMLElement>) => {
		const { rate } = e.currentTarget.dataset;
		const { setPopUp, settings } = this.props;
		setPopUp(RATE_POPUP, {
			rate,
			rates: settings.data.filters.range,
			onClose: () => {
				setPopUp(null);
			},
		});
	};

	onBtnVideoClick = (e: SyntheticEvent<HTMLElement>) => {
		e.preventDefault();
		const { setPopUp } = this.props;
		setPopUp(VIDEO_POPUP, {
			onClose: () => {
				setPopUp(null);
			},
		});
	};

	handleShowFiltersClick = () => {
		const { openFilters } = this.state;

		this.setState({ openFilters: !openFilters });
	};

	renderNoMatch = () => {
		const topTetx = 'לא מצאנו מה שחיפשת, \nובאמת שבדקנו בכל פינה בחניון.';
		const bottomText = 'נוכל לעזור לך עם חיפוש נוסף?';
		return (
			<div className={css.noMatch}>
				<img src={noMatchImage} alt="" />
				<div className={css.topText}>{topTetx}</div>
				<div className={css.bottomText}>{bottomText}</div>
			</div>
		);
	};

	renderSimilar = () => {
		const { similar, settings } = this.props;
		const similarTitle = 'מכוניות דומות:';

		return (
			<div className={css.similarItems}>
				<div className={css.title}>{similarTitle}</div>
				{_.map(similar, item => {
					return (
						<SimilarItem
							key={item.id}
							id={item.id}
							carTitle={item.title}
							carDescription={item.description}
							category={item.category}
							createdDate={item.createdDate}
							video={item.video}
							mainImage={item.mainImage}
							rate={item.rate}
							rates={settings.data.filters.range}
							rateClick={this.onBtnRateClick}
							videoBtnClick={this.onBtnVideoClick}
							name={item.name}
						/>
					);
				})}
			</div>
		);
	};

	render() {
		const {
			className,
			transitionState,
			results,
			settings,
			searchPhrase,
			prevPath,
			car,
			isBusy,
			userFilters,
		} = this.props;
		const { openFilters } = this.state;

		const sortedResults = !_.isEmpty(results) ? _.orderBy(results, 'price', 'asc') : [];

		const path = this.props.prevPath.replace('/car/', '');
		const pageName = path !== ROOT_ROUTE && !_.isEmpty(car) ? _.get(car, 'name', '') : 'מסך הבית';
		const serchPhrase = _.isEmpty(searchPhrase) ? '' : searchPhrase;
		const filtersValues = _.map(userFilters, (i, index: number) => {
			return { to: '', name: `${i}` };
		});

		const locationPath: Array<any> = [
			{ to: prevPath, name: pageName },
			{ to: '', name: serchPhrase },
			...filtersValues,
		];

		const filterTrigger = 'סנן תוצאות חיפוש';
		const serchResTitle = 'תוצאות החיפוש';
		// const showResults = !_.isEmpty(sortedResults) && !isBusy;
		return (
			<div className={classNames(css.searchPage, css[transitionState], className)}>
				{this.renderHelmet()}
				<Header isFull withFilters={!_.isEmpty(sortedResults)} />
				{!_.isEmpty(sortedResults) && (
					<React.Fragment>
						<div className={css.top}>
							<div>
								<Responsive query={Responsive.DESCTOP}>
									<Breadcrumbs path={locationPath} />
								</Responsive>
								<div className={css.searchPhrase}>
									<div className={css.title}>{serchResTitle}</div>
									<div className={css.phrase}>{serchPhrase}</div>
								</div>
							</div>
							<Responsive query={Responsive.DESCTOP}>
								<React.Fragment>
									<div className={css.filtersTrigger} onClick={this.handleShowFiltersClick}>
										{filterTrigger}
									</div>
									{openFilters && <Filters />}
								</React.Fragment>
							</Responsive>
						</div>
						<div className={css.content}>
							<div className={css.searchResults}>
								<div className={css.resultsGrid}>
									{_.map(sortedResults, (item: CarsItem, i) => {
										return (
											<ResultItem
												key={item.id}
												id={item.id}
												carTitle={item.title}
												category={item.category}
												video={item.video}
												mainImage={item.mainImage}
												rate={item.rate}
												rates={settings.data.filters.range}
												rateClick={this.onBtnRateClick}
												videoBtnClick={this.onBtnVideoClick}
												isFirst={i === 0}
												name={item.name}
											/>
										);
									})}
								</div>
								{this.renderSimilar()}
							</div>
							<aside></aside>
						</div>
					</React.Fragment>
				)}
				{_.isEmpty(sortedResults) && !isBusy && this.renderNoMatch()}
				<FooterBanner />
			</div>
		);
	}
}

export default withTransition(SearchPage);
