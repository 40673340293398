// @flow
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import { fileLink, isIOS } from 'common/utils';
import _ from 'lodash';

import { Helmet } from 'react-helmet-async';
import { VIDEO_POPUP, RATE_POPUP } from 'client/constants';
import Header from 'client/components/common/Header';
import { Responsive } from 'client/components/common/Responsive';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import FooterBanner from 'client/components/common/FooterBanner';
import faviconImg from 'assets/favicon1.png';
import CarSlider from './CarSlider';
import Asside from './Asside';
import * as css from './CarPage.scss';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
	settings: Settings,
	car?: CarsItem,
	getCar: Function,
	match?: RouterMatch,
	setPopUp: Function,
|};

type State = {|
	isBannerClosed: boolean,
|};

class CarPage extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;
	slideBannerTimeoutID: TimeoutID;

	// eslint-disable-next-line react/sort-comp
	static isBannerClosed = false;

	static defaultProps = {
		className: '',
		match: {},
		car: null,
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			isBannerClosed: false,
		};
	}

	componentDidMount() {
		const { onTransitionComplete, getCar, match, car } = this.props;
		const id = _.get(match, 'params.id', '');
		this.transitionCompleteTimeout = setTimeout(onTransitionComplete, parseInt(css.transitionIn, 10));
		if (!car || id !== car.id) {
			getCar({ collection: 'cars', id });
		}

		if (document && !CarPage.isBannerClosed) {
			const bannerWrapDIV = document.createElement('div');
			const bannerDIV = document.createElement('div');

			bannerWrapDIV.classList.add(css.slideBanner);
			bannerDIV.classList.add(css.inner);
			bannerDIV.id = this.bannerID;
			bannerWrapDIV.appendChild(bannerDIV);

			if (document.body && !window.SHOWN_BANNERS[this.bannerID]) {
				const { googletag } = window;

				document.body.appendChild(bannerWrapDIV);
				googletag.cmd.push(() => {
					googletag
						.defineSlot(
							this.bannerURL,
							[
								[1, 1],
								[2, 1],
							],
							this.bannerID
						)
						.addService(googletag.pubads());
					googletag.pubads().enableSingleRequest();
					googletag.enableServices();
				});

				googletag.cmd.push(() => googletag.display(this.bannerID));
				googletag.pubads().addEventListener('slotRenderEnded', event => {
					this.slideBannerTimeoutID = setTimeout(() => {
						CarPage.isBannerClosed = true;
						this.setState({ isBannerClosed: true });
					}, 5000);
				});

				window.SHOWN_BANNERS[this.bannerID] = true;
			}
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	componentWillUnmount() {
		clearTimeout(this.slideBannerTimeoutID);
	}

	get bannerID() {
		if (Responsive.isMatching(Responsive.DESKTOP)) {
			return 'div-gpt-ad-1615880654154-0';
		}

		if (isIOS()) {
			return 'div-gpt-ad-1615880900177-0';
		}

		return 'div-gpt-ad-1615880713642-0';
	}

	get bannerURL() {
		if (Responsive.isMatching(Responsive.DESKTOP)) {
			return '/6870/ynet/desktop/interstitial/ypartner.cars';
		}

		if (isIOS()) {
			return '/6870/ynet/mobileweb_iphone/native.ad/ypartner.cars';
		}

		return '/6870/ynet/mobileweb_android/interstitial.inner/ypartner.cars';
	}

	renderHelmet = () => {
		const { car } = this.props;
		// eslint-disable-next-line max-len
		const title = _.get(car, 'seoTitle', '');
		// eslint-disable-next-line max-len
		const description = _.get(car, 'seoDescription', '');
		const ogTitle = _.get(car, 'shareTitle', '');
		const ogDescription = _.get(car, 'shareDescription', '');
		const image = fileLink(_.get(car, 'shareImage', ''));
		return (
			<Helmet
				title={title}
				link={[{ rel: 'icon', type: 'image/png', href: faviconImg }]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: ogTitle },
					{ property: 'og:description', content: ogDescription },
					{ property: 'og:image', content: image },
				]}
			/>
		);
	};

	onBtnVideoClick = (e: SyntheticEvent<HTMLElement>) => {
		e.preventDefault();
		const { setPopUp, car } = this.props;
		const videoSrc = _.get(car, 'video', '');

		setPopUp(VIDEO_POPUP, {
			videoSrc,
			onClose: () => {
				setPopUp(null);
			},
		});
	};

	onRateClick = (e: SyntheticEvent<HTMLElement>) => {
		const { rate } = e.currentTarget.dataset;
		const { setPopUp, settings } = this.props;
		setPopUp(RATE_POPUP, {
			rate,
			rates: settings.data.filters.range,
			onClose: () => {
				setPopUp(null);
			},
		});
	};

	render() {
		const { className, transitionState, car, settings } = this.props;
		const { isBannerClosed } = this.state;
		const carMedia = _.map(_.get(car, 'media', []), i => {
			if (fileLink(i.mediaField) === '') return;
			return fileLink(i.mediaField);
		});
		const video = _.get(car, 'video', '');
		const totalMedia = _.reverse([fileLink(_.get(car, 'mainImage', '')), ...carMedia]);
		// if (_.isEmpty(car)) return null;
		const shouldRenderSlideBanner = !isBannerClosed && !CarPage.isBannerClosed;

		return (
			<div className={classNames(css.carPage, css[transitionState], className)}>
				{this.renderHelmet()}
				<Header />
				{car && settings && <Asside info={car} settings={settings} rateClick={this.onRateClick} />}

				{totalMedia.length > 1 && (
					<CarSlider
						totalMedia={totalMedia}
						hasVideo={!!video}
						videoLink={video || ''}
						videoBtnClick={this.onBtnVideoClick}
					/>
				)}

				{totalMedia.length === 1 && (
					<div
						className={css.media}
						style={{ backgroundImage: `url(${fileLink(_.get(car, 'mainImage', ''))})` }}
					></div>
				)}
				{!shouldRenderSlideBanner && <FooterBanner />}
			</div>
		);
	}
}

export default withTransition(CarPage);
