// @flow
import { connect } from 'react-redux';
import * as React from 'react';

import Component from './PopUpManager';

const mapStateToProps = (state: ClientStore) => ({
	popUp: state.popUp,
});

export const PopUpManager = (connect(mapStateToProps)(Component): React.AbstractComponent<{}>);
