/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unescaped-entities */
// @flow
/* eslint-disable react/no-danger */
import { Responsive } from 'client/components/common';
import Range from 'client/components/common/Range';
import * as React from 'react';
import RateTags from '../RateTags';
import * as css from './GeneralInfo.scss';

type Props = {|
	category?: string,
	title?: string,
	description?: string,
	rate: string,
	color: string,
	rangeTitle: string,
	rateClick: Function,
	goodTags: string,
	badTags: string,
	normalTags: string,
	price: number,
|};

class GeneralInfo extends React.PureComponent<Props> {
	static defaultProps = {
		category: '',
		title: '',
		description: '',
	};

	componentDidMount() {}

	componentDidUpdate(prevProps: Props) {}

	render() {
		const {
			category,
			title,
			description,
			rate,
			color,
			rangeTitle,
			rateClick,
			goodTags,
			badTags,
			normalTags,
			price,
		} = this.props;
		return (
			<div className={css.generalInfo}>
				{Responsive.isMatching(Responsive.MOBILE) && (
					<div className={css.mobileRange} data-rate={rate} onClick={rateClick}>
						<Range withAnimation={false} color={color} range={rate} type="medium" title={rangeTitle} />
					</div>
				)}
				<div className={css.itemCategory}>
					<span>{category}</span>
				</div>
				<h1 className={css.itemTitle}>{title}</h1>
				<div className={css.itemDescription} dangerouslySetInnerHTML={{ __html: description }}></div>
				{Responsive.isMatching(Responsive.DESKTOP) && (
					<RateTags
						goodTags={goodTags}
						badTags={badTags}
						normalTags={normalTags}
						rate={rate}
						color={color}
						title={rangeTitle}
						rateClick={rateClick}
						price={price}
					/>
				)}
				<div className={css.additionalLinks}>
					<p>
						בין מומחי הרכב שהערכתם לכל דגם ב"מנוע חיפוש" נלקחה בחשבון, כתבי הרכב של המגזינים 
						<a href="https://www.autocar.co.uk/" rel="noopener noreferrer" target="_blank">
							אוטוקאר
						</a>
						,{' '}
						<a href="https://www.autoexpress.co.uk/" rel="noopener noreferrer" target="_blank">
							אוטואקספרס
						</a>{' '}
						ו
						<a href="https://www.topgear.com/" rel="noopener noreferrer" target="_blank">
							טופגיר
						</a>{' '}
						הבריטיים,{' '}
						<a href="https://www.topgear.com/" rel="noopener noreferrer" target="_blank">
							אוטו, מוטור וספורט{' '}
						</a>{' '}
						ו
						<a href="https://www.autobild.de/" rel="noopener noreferrer" target="_blank">
							אוטובילד
						</a>{' '}
						הגרמניים,{' '}
						<a href="https://www.caradvice.com.au/" rel="noopener noreferrer" target="_blank">
							קאראדוויז
						</a>{' '}
						האוסטרלי, 
						<a href="https://www.roadandtrack.com/" rel="noopener noreferrer" target="_blank">
							רואד אנד טראק
						</a>
						,{' '}
						<a href="https://www.caranddriver.com/" rel="noopener noreferrer" target="_blank">
							קאר אנד דרייבר
						</a>{' '}
						ו
						<a href="https://www.motortrend.com/" rel="noopener noreferrer" target="_blank">
							מוטורטרנד
						</a>{' '}
						האמריקאיים ואחרים.
					</p>
				</div>
			</div>
		);
	}
}

export default GeneralInfo;
