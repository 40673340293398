// @flow
import * as React from 'react';
import { fileLink } from 'common/utils';
import { Responsive } from 'client/components/common';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { CAR_ROUTE } from 'client/constants';
// import playBtn from 'assets/client/play-btn.svg';
import Range from 'client/components/common/Range';
import { dataLayerEvents } from 'common/utils/gtag';
import * as css from './GeneralContentItem.scss';

type Props = {
	item: CarsItem,
	rates: Array<RangeType>,
	rateClick: Function,
	// videoBtnClick: Function,
};

class GeneralContentItem extends React.PureComponent<Props> {
	onClickLink = (e: SyntheticEvent<HTMLLinkElement>) => {
		const { name } = e.currentTarget.dataset;
		dataLayerEvents.carItemClick(name);
	};
	render() {
		const { item, rates, rateClick } = this.props;
		const neededRateInfo: ?RangeType = _.find<RangeType>(rates, { range: item.rate });
		const rangeType = Responsive.isMatching(Responsive.DESKTOP) ? 'medium' : 'medium';
		return (
			<div className={css.item}>
				{/* {!item.video && ( */}
				<Link
					data-name={item.name}
					to={`${CAR_ROUTE.replace(':id', item.id)}`}
					onClick={this.onClickLink}
					className={css.mediaWrapper}
				>
					<div className={css.media} style={{ backgroundImage: `url(${fileLink(item.mainImage)})` }}></div>
				</Link>
				{/* )} */}
				{/* {item.video && (
					<div className={css.mediaWrapper}>
						<Link to={`${CAR_ROUTE.replace(':id', item.id)}`}>
							<div
								className={css.media}
								style={{ backgroundImage: `url(${fileLink(item.mainImage)})` }}
							></div>
						</Link>
						<div className={css.playBtn} onClick={this.props.videoBtnClick} data-src={item.video}>
							<img src={playBtn} alt="" />
						</div>
					</div>
				)} */}
				<div className={css.info}>
					<div className={css.rate}>
						<div className={css.rateInner} data-rate={item.rate} onClick={rateClick}>
							<Range
								withAnimation
								color={_.get(neededRateInfo, 'color', '')}
								range={_.get(neededRateInfo, 'range', '')}
								type={rangeType}
								title={_.get(neededRateInfo, 'title', '')}
								onHoverAnimation
							/>
						</div>
					</div>
					<div className={css.carCategory}>
						<p>{item.category}</p>
					</div>
					<Link data-name={item.name} to={`${CAR_ROUTE.replace(':id', item.id)}`} onClick={this.onClickLink}>
						<div className={css.title}>
							<p>{item.title}</p>
						</div>
					</Link>
					<div className={css.description} dangerouslySetInnerHTML={{ __html: item.description }}></div>
				</div>
			</div>
		);
	}
}

export default GeneralContentItem;
