import _ from 'lodash';

import * as actions from 'client/constants/actions';

export function setPopUp(id, data = null) {
	let targetId = id;

	if (_.isNull(id)) {
		targetId = '';
	}

	return dispatch => dispatch({ type: actions.SET_POP_UP, payload: { id: targetId, data } });
}
